import React, { useState } from 'react';
import useFormValidation from '../../../hooks/useFormValidation';
import validate from '../../../hooks/validate';
import { submitEarlyAccessRequest } from '../../../services/apiService';
import handleFormSubmit from '../constant/handleSubmit';
import { Modal } from 'react-bootstrap';
import ThankYouModal from '../Modal/ThankYouModal';

const FormSection = () => {
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const submitForm = async (values) => {
    await handleFormSubmit(
      submitEarlyAccessRequest,
      values,
      setShowThankYouModal,
      resetForm,
      () => {}, 
      setIsSubmitting,
      setSubmitError
    );
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    resetForm,
  } = useFormValidation(
    { email: '', phone: '', numberOfEmployeess: '' },
    (values) => validate(values, 'earlyAccess'),
    submitForm
  );

  return (
    <div className="form-div" id="quick-form">
      <form id="myForm" onSubmit={handleSubmit}>
        <h2 className="text-center banner-form-heading bluetxt">Join Early Access Program Today</h2>
        <p className="text-center formh-txt bluetxt">(1 year FREE subscription + Biometric device)</p>
        <div className="form-group">
          <label htmlFor="email">Email*</label>
          <input
            type="email"
            id="email"
            name="email"
            className={`form-control ${errors.email && 'is-invalid'}`}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number*</label>
          <input
            type="text"
            id="phone"
            name="phone"
            className={`form-control ${errors.phone && 'is-invalid'}`}
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.phone && <p className="error">{errors.phone}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="employee_count">Numbers of Employee*</label>
          <select
            id="employee_count"
            name="numberOfEmployeess"
            className={`form-control ${errors.numberOfEmployeess && 'is-invalid'}`}
            value={values.numberOfEmployeess}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="">Please Select</option>
            <option>1-50</option>
            <option>51-100</option>
            <option>101-200</option>
            <option>201-500</option>
          </select>
          {errors.numberOfEmployeess && <p className="error">{errors.numberOfEmployeess}</p>}
        </div>
        <div className="joinbtn-div">
          <button className="btn-join btn-blue btn-join" id="submit" type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Join Today'}
          </button>
          {submitError && <p className="error">{submitError}</p>}
        </div>
      </form>
      <Modal show={showThankYouModal} onHide={() => setShowThankYouModal(false)} centered>
        <ThankYouModal onClose={() => setShowThankYouModal(false)} />
      </Modal>
    </div>
  );
};

export default FormSection;
