// BlogDetail.js
import React from 'react';
import { useParams } from 'react-router-dom';
import NotFoundPage from './NotFoundPage';

// Import all your blog content components
import AccurateAttendanceTrackingImportance from '../components/common/Blog/AccurateAttendanceTrackingImportance';
import AttendanceDataWorkforceOptimization from '../components/common/Blog/AttendanceDataWorkforceOptimization';
import AttendanceIssueOvercome from '../components/common/Blog/AttendanceIssueOvercome';
import CloudVsOnPremise from '../components/common/Blog/CloudVsOnPremise';
import FiveReasonsToInvest from '../components/common/Blog/FiveReasonsToInvest';
import GuideOnlineAttendance from '../components/common/Blog/GuideOnlineAttendance';
import LeaveManagementGuide from '../components/common/Blog/LeaveManagementGuide';
import ShiftTipsBestPractices from '../components/common/Blog/ShiftTipsBestPractices';
import SixKeyChallenge from '../components/common/Blog/SixKeyChallenge';
import NoTouch from '../components/common/Blog/NoTouch';
import UnionBudget from '../components/common/Blog/UnionBudget';
import { Helmet } from 'react-helmet';

const componentMap = {
  'why-accurate-attendance-matters-for-growth': AccurateAttendanceTrackingImportance,
  'leverage-attendance-data-for-workforce-management': AttendanceDataWorkforceOptimization,
  'strategies-for-overcoming-attendance-issues': AttendanceIssueOvercome,
  'on-premise-vs-cloud-hr-software-benefits': CloudVsOnPremise,
  'reasons-to-invest-in-workforce-management-2024': FiveReasonsToInvest,
  'guide-to-implementing-online-attendance-systems': GuideOnlineAttendance,
  'comprehensive-leave-management-guide-for-2024': LeaveManagementGuide,
  'shift-management-tips-and-best-practices': ShiftTipsBestPractices,
  'challenges-in-leave-management-and-solutions': SixKeyChallenge,
  'transform-sme-attendance-with-face-recognition': NoTouch,
  "could-the-2024-union-budget-transform-india's-workforce-landscape": UnionBudget,
};

const BlogDetail = () => {
  const { id } = useParams(); // Get the id parameter from the URL

  // Find the component based on the id
  const BlogComponent = componentMap[id];

  if (!BlogComponent) {
    return <NotFoundPage />; 
  }

  return (
    <div>
      <Helmet>
        <title>Mewurk  Blog |  HR Software Solutions Blog</title>
        <meta name="description" content="Explore the Mewurk Blog for the latest insights and trends in HR management. Stay informed with expert advice, tips, and articles on workforce solutions" />
      </Helmet>
      <BlogComponent />
    </div>
  );
};

export default BlogDetail;
