import React from 'react'
import logo from '../../assets/images/logo.svg';
import rocketIcon from '../../assets/images/rocket.svg';
import mobileIcon from '../../assets/images/mobileicon.svg';
import biometricIcon from '../../assets/images/biomeric.svg';
import supportIcon from '../../assets/images/alldaytime.svg';
import ratingImg from '../../assets/images/landing-rating.svg';
import clientMivanta from '../../assets/images/home-mivanta.png';
import clientMefron from '../../assets/images/home-mefron.jpg';
import clientMupizo from '../../assets/images/home-mupizo.jpg';
import clientMoreze from '../../assets/images/moreze.png';
import clientServico from '../../assets/images/servico.png';
import responsiveMockup from '../../assets/images/responsive-mockup.png';
import automatesIcon from '../../assets/images/automates.svg';
import dataAnalyticsIcon from '../../assets/images/dataanalytics.svg';
import expertiseIcon from '../../assets/images/expertise.svg';
import cloudIcon from '../../assets/images/cloud.svg';
import attendanceIcon from '../../assets/images/attendance-icon.svg';
import leaveIconOrange from '../../assets/images/leave-icon-orange.svg';
import shiftIcon from '../../assets/images/shift-icon.svg';
import coreHrIconOrange from '../../assets/images/corehr-icon-orange.svg';
import documentIconOrange from '../../assets/images/document-icon-orange.svg';
import workflowIcon from '../../assets/images/workflow-icon.svg';
import payrollIcon from '../../assets/images/payroll-icon.svg';
import biometricIconBlue from '../../assets/images/biometric-icon.svg';
import mobileGrowth from '../../assets/images/mobile-growth.png';
import callToActionLanding from '../../assets/images/callToActionLanding.png';
import fbIcon from '../../assets/images/fb-icon.svg';
import twitterIcon from '../../assets/images/twitter-icon.svg';
import linkedinIcon from '../../assets/images/linkedin-icon.svg';
import FormWithAllDetails from '../../components/common/LandingPage/FormWithAllDetails';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import {constant} from '../../components/common/constant/constant';

const LandingPage = () => {
  return (
    <div>
        <section className="header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 text-left mobile-left">
                        <Link to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 text-right mobile-right">
                    <ScrollLink to="quick-form" smooth={true} duration={500} className="text-white btn-orange book-button">Start for FREE</ScrollLink>
                    </div>
                </div>
            </div>
        </section>
        <section className="banner">
            <div className="landing-banner-image">
                <div className="container-fluid py-5">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="banner-left-content">
                                <h5 className="yellowtxt2 font-30">Meet Mewurk,</h5>
                                <h1 className="text-white font-35px">The #1 Time & Attendance<br /> software in Ahmedabad</h1>
                                <ul className="p-0 text-white list-unstyled font-n20 mt-30 mb-60 text-capitalize">
                                    <li className="pt-4"><img src={rocketIcon} alt="icon" className="mr-3" /> FREE(Limited time) for SME’s & Startups</li>
                                    <li className="pt-4"><img src={mobileIcon} alt="icon" className="mr-3" /> Mobile App on the Go</li>
                                    <li className="pt-4"><img src={biometricIcon} alt="icon" className="mr-3" /> Get biometric device</li>
                                    <li className="pt-4"><img src={supportIcon} alt="icon" className="mr-3" /> 24x7 tech support</li>
                                </ul>
                                <h6 className="font-25 text-white">Beta customers Rated</h6>
                                <p className="ratingtxt text-white">
                                    <img src={ratingImg} className="img-fluid" alt="rating" />
                                    <span className="position-absolute mt-1 ml-2">Rated 4.8</span>
                                </p>
                            </div>
                        </div>
                        <FormWithAllDetails />
                    </div>
                </div>
            </div>
        </section>
        <section className="founder-mewurk p-60 pb-60">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <h4 className="bluetxt text-center font-35">Founders and HRs of 100+ SMEs & businesses trust Mewurk</h4>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 p-60">
                        <img src={clientMivanta} alt="clients" />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 p-60">
                        <img src={clientMefron} alt="clients" />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 p-60">
                        <img src={clientMupizo} alt="clients" />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 p-60">
                        <img src={clientMoreze} alt="clients" />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 p-60">
                        <img src={clientServico} alt="clients" />
                    </div>
                </div>
            </div>
        </section>
        <section className="why pb-60">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="orangetxt text-center font-35">Why Mewurk Works</h4>
                    </div>
                </div>
            </div>
        </section>
        <section className="why-sec pb-60 p-60 bg-gray">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <img src={responsiveMockup} className="img-fluid" width="100%" alt="mockup" />
                        <ScrollLink to="quick-form" smooth={true} duration={500} className="text-white btn-join btn-orange float-lg-right">Try Now</ScrollLink>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="card-secure text-center mb-50">
                                    <img src={automatesIcon} className="img-fluid" alt="integrated" />
                                    <h4 className="font-25 bluetxt font-w600 mt-20 mb-0">Simplifies & <br />Automates</h4>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="card-secure text-center float-lg-right mb-50">
                                    <img src={dataAnalyticsIcon} className="img-fluid" alt="mobilefirst" />
                                    <h4 className="font-25 orangetxt font-w600 mt-20 mb-0">Powerful data <br />analytics</h4>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="card-secure text-center">
                                    <img src={expertiseIcon} className="img-fluid" alt="mobilefirst" />
                                    <h4 className="font-25 orangetxt font-w600 mt-20 mb-0">20+ years Of <br />expertise</h4>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="card-secure text-center float-lg-right">
                                    <img src={cloudIcon} className="img-fluid" alt="seamlessly" />
                                    <h4 className="font-25 bluetxt font-w600 mt-20 mb-0">On cloud & <br />mobile</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="mewurkdo p-60 pb-60">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2 className="bluetxt font-35">What Mewurk can do for your Business</h2>
                    </div>
                </div>
                <div className="row p-60">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 mb-50">
                        <div className="card-value text-center h-100 bg-gray">
                            <img src={attendanceIcon} className="img-fluid" alt="attendance" />
                            <h3 className="font-20 font-weight-bolder bluetxt mt-20">Attendance</h3>
                            <p className="font-16 text-dark mt-20">Real-time attendance management with custom policies & Biometric integration</p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 mb-50">
                        <div className="card-value text-center h-100 bg-gray">
                            <img src={leaveIconOrange} className="img-fluid" alt="leave" />
                            <h3 className="font-20 font-weight-bolder orangetxt mt-20">Leave</h3>
                            <p className="font-16 text-dark mt-20">Setup leave policies, while applying and approving from anywhere.</p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 mb-50">
                        <div className="card-value text-center h-100 bg-gray">
                            <img src={shiftIcon} className="img-fluid" alt="Shift" />
                            <h3 className="font-20 font-weight-bolder bluetxt mt-20">Shift</h3>
                            <p className="font-16 text-dark mt-20">Set and run multiple shift policies and patterns for quick alterations & roasters</p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 mb-50">
                        <div className="card-value text-center h-100 bg-gray">
                            <img src={coreHrIconOrange} className="img-fluid" alt="corehr" />
                            <h3 className="font-20 font-weight-bolder orangetxt mt-20">Core HR</h3>
                            <p className="font-16 text-dark mt-20">Configure employee rights, org structure and setup with customer workflows as HR</p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 mb-50">
                        <div className="card-value text-center h-100 bg-gray">
                            <img src={documentIconOrange} className="img-fluid" alt="Document" />
                            <h3 className="font-20 font-weight-bolder orangetxt mt-40">Document Management</h3>
                            <p className="font-16 text-dark mt-20">Central Management & sharing of important documents, policies, & assets.</p>
                        </div>import constant from './../../components/common/constant/constant';

                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 mb-50">
                        <div className="card-value text-center h-100 bg-gray">
                            <img src={workflowIcon} className="img-fluid" alt="Workflow" />
                            <h3 className="font-20 font-weight-bolder bluetxt mt-40">Workflow Automation</h3>
                            <p className="font-16 text-dark mt-20">Admin can set and manage custom workflows and policies, starting from onboarding to off-boarding</p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 mb-50">
                        <div className="card-value text-center h-100 bg-gray">
                            <img src={biometricIconBlue} className="img-fluid" alt="Biometric" />
                            <h3 className="font-20 font-weight-bolder orangetxt mt-40">Biometric Kiosk</h3>
                            <p className="font-16 text-dark mt-20">Touchless attendance integration with face recognition capabilities & AI-enabled device</p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 mb-50">
                        <div className="card-value text-center h-100 bg-gray">
                            <img src={payrollIcon} className="img-fluid" alt="Payroll" />
                            <h3 className="font-20 font-weight-bolder bluetxt mt-40">Payroll</h3>
                            <p className="font-16 text-dark mt-20">Minimize payroll hassle, and automate payroll export with multiple salary structure templates and tools</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <ScrollLink to="quick-form" smooth={true} duration={500} className="text-white btn-join btn-blue">Book a Demo</ScrollLink>
                    </div>
                </div>
            </div>
        </section>
        <section className="why-sec pb-60 p-60 bg-gray">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <img src={mobileGrowth} className="img-fluid" alt="mobile" />
                        <h3 className="font-30 bluetxt font-w600">Why Mewurk is</h3>
                        <h1 className="font-40 bluetxt font-w600">#1 HR & Attendance<br /> software for SMEs in India <br />& Ahmedabad.</h1>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-50">
                                <div className="card-value h-100 text-center bg-blue">
                                    <h3 className="font-40 font-weight-bold text-white mt-40">2000+</h3>
                                    <h3 className="font-22 text-white">Hrs saved</h3>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-50">
                                <div className="card-value h-100 text-center bg-orange">
                                    <h3 className="font-40 font-weight-bold text-white mt-40">15+</h3>
                                    <h3 className="font-22 text-white">modules integrated</h3>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-50">
                                <div className="card-value h-100 text-center bg-orange">
                                    <h3 className="font-40 font-weight-bold text-white mt-40">5000+</h3>
                                    <h3 className="font-22 text-white">active users</h3>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-50">
                                <div className="card-value h-100 text-center bg-blue">
                                    <h3 className="font-40 font-weight-bold text-white mt-40">7+</h3>
                                    <h3 className="font-22 text-white">Industry served</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="callToAction pb-60 pt-60">
            <div className="container-fluid">
                <div className="row bg-blue border-radius">
                    <div className="col-md-4">
                        <div className="landingPageimgdiv">
                            <img src={callToActionLanding} alt="mobile" />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="content-try">
                            <h4 className="text-white font-35px font-weight-normal m-0">Are you ready to</h4>
                            <h2 className="text-white font-40px mb-30">empower your workforce?</h2>
                            <ScrollLink to="quick-form" smooth={true} duration={500} className="margin-16 text-white btn-join btn-orange white-bg-bluetext">Get Started</ScrollLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="footer bg-gray ptb-20">
            <div className="container-fluid">
                <div className="row footer-bottom">
                    <div className="col-md-8 text-white">
                        <h2 className="font-18 text-dark m-0">Copyright © 2024. Mewurk Technologies Pvt. Ltd. All Rights Reserved.</h2>
                    </div>
                    <div className="col-md-4 text-white text-right">
                        <p className="font-18 text-dark m-0 socialp"><span className="mr-2">Connect with us :</span> 
                        <Link to={constant.mewurkfb} target="_blank" rel="noopener noreferrer">
                                <img src={fbIcon} className="img-fluid m-1" alt="fb" />
                            </Link>
                            <Link to={constant.mewurktwitter} target="_blank" rel="noopener noreferrer">
                                <img src={twitterIcon} className="img-fluid m-1" alt="twitter" />
                            </Link>
                            <Link to={constant.mewurklinkedin} target="_blank" rel="noopener noreferrer">
                                <img src={linkedinIcon} className="img-fluid m-1" alt="linkedin" />
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default LandingPage
