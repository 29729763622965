
import blogCardImg1 from '../../../assets/images/blog-drivegrowths.jpg';
import blogCardImg2 from '../../../assets/images/blog-attendencedata.jpg';
import blogCardImg3 from '../../../assets/images/blog-cloudpremise.jpg';
import blogCardImg4 from '../../../assets/images/blog-leave-managmentss.jpg';
import blogCardImg5 from '../../../assets/images/blog-shiftblogs.jpg';
import blogCardImg6 from '../../../assets/images/blog-kiosk.jpg';
import blogCardImg7 from '../../../assets/images/blog-online-attendancesm.jpg';
import blogCardImg8 from '../../../assets/images/blog-solve-workplacesm.jpg';
import blogCardImg9 from '../../../assets/images/blog-leave-challenges-sm.jpg';
import blogCardImg10 from '../../../assets/images/blog-workforce-solution-sm.jpg';
import blogCardImg11 from '../../../assets/images/unionbudget-index.jpg';

const allBlogsData = [
    {
      id:1,
      title: 'Why Accurate Attendance Matters for Growth',
      imageSrc: blogCardImg1,
      description: 'Accurate attendance and leave management are crucial for businesses...',
      date: '05/01/2024',
      readingTime: '5 Minutes Read',
      category: 'attendance-management'
    },
    {
      id:2,
      title: 'Leverage Attendance Data for Workforce Management',
      imageSrc: blogCardImg2,
      description: 'Imagine an office of a startup company with just 25 employees...',
      date: '17/01/2024',
      readingTime: '5 Minutes Read',
      category: 'leave-management'
    },
    {
      id:3,  
      title: "On-Premise vs Cloud HR Software Benefits",
        imageSrc: blogCardImg3,
        description: "Can you believe that in 2024, there was a time when people had to go to ..",
        date: "02/02/2024",
        readingTime: "7 Minutes Read",
        category: "shift-management"
      },
      {
        id:4,
        title: "Comprehensive Leave Management Guide for 2024",
        imageSrc: blogCardImg4,
        description: "Leave management has always been one of the biggest nightmares of HR professionals..",
        date: "15/02/2024",
        readingTime: "7 Minutes Read",
        category: "attendance-management"
      },
      {
        id:5,
        title: "Shift Management Tips and Best Practices",
        imageSrc: blogCardImg5,
        description: "If the shift is good, I'm good to work. We've all heard it. Managing employees..",
        date: "05/03/2024",
        readingTime: "5 Minutes Read",
        category: "attendance-management"
      },
      {
        id:6,
        title: "Transform SME Attendance with Face Recognition",
        imageSrc: blogCardImg6,
        description: "The integration of biometric attendance devices like fingerprint clock in systems brought..",
        date: "14/03/2024",
        readingTime: "4 Minutes Read",
        category: "attendance-management"
      },
      {
        id:7,
        title: "Guide to Implementing Online Attendance Systems",
        imageSrc: blogCardImg7,
        description: "For HR managers, one of the biggest headaches is dealing with attendance records and then...",
        date: "16/04/2024",
        readingTime: "5 Minutes Read",
        category: "attendance-management"
      },
      {
        id:8,
        title: "Strategies for Overcoming Attendance Issues",
        imageSrc: blogCardImg8,
        description: "Remember those old-school days! We'd hurry to class before the bell, eager to shout..",
        date: "26/04/2024",
        readingTime: "5 Minutes Read",
        category: "attendance-management"
      },
      {
        id:9,
        title: "Challenges in Leave Management and Solutions",
        imageSrc: blogCardImg9,
        description: "To an outsider, leave management might seem straightforward. An employee requests time off..",
        date: "10/05/2024",
        readingTime: "5 Minutes Read",
        category: "attendance-management"
      },
      {
        id:10,
        title: "Reasons to Invest in Workforce Management 2024",
        imageSrc: blogCardImg10,
        description: "Integrated workforce management systems are gaining significant attention these days. The..",
        date: "25/05/2024",
        readingTime: "5 Minutes Read",
        category: "attendance-management"
      },
      {
        id:10,
        title: "Could the 2024 Union Budget Transform India's Workforce Landscape?",
        imageSrc: blogCardImg11,
        description: "The business world is still buzzing about the Union Budget 2024. While the primary focus..",
        date: "31/07/2024",
        readingTime: "5 Minutes Read",
        category: "attendance-management"
      }
  ];


  export default allBlogsData;