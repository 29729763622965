import React, { createContext, useState, useEffect } from 'react';
import CareerIt from '../../../assets/images/careers-it.png'
import CareersMarketing from '../../../assets/images/careers-marketing.png'
// Create the JobContext
const JobContext = createContext();

// JobProvider component
const JobProvider = ({ children }) => {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        // Initialize jobs data
        const jobData = [
                {
                    title: "Team Lead - Backend",
                    icon: CareerIt,
                    location: "Bangalore",
                    department: "Software Engineering",
                    posted: "June 21, 2024",
                    employment: "Permanent",
                    workMode: "Work from office",
                    experience: "8+ years",
                    openings: 1,
                    qualifications: [
                        "8+ years of Software Development experience in .NET Core, REST API, MS SQL, Nosql, Microservices Design Patterns.",
                        "Hands-on coding experience mandatory",
                        "Experience with Cloud (AWS and Azure) deployments",
                        "Solid written and verbal communication skills",
                        "Able to thrive in a fast-paced environment",
                        "Engineering Graduates / Post-Graduates from premier institutions are preferred.",
                        "More than 6+ years of hands-on product engineering experience."
                    ],
                    skills: [
                        "Experience in software development, product development organization experience preferred.",
                        "Be able to distill business objectives into technical solutions through effective system design and architecture",
                        "Be able to work independently on a project-by project basis and also work in a collaborative and fast-paced team environment",
                        "Be able to provide technical and analytical solutions to evaluate the merits and challenges of a product idea.",
                    ],
                    benefits: [
                        "Gain experience rapidly",
                        "Work directly with executive team",
                        "Fast-paced work environment",
                    ],
                    jobDescription: [
                        "We’re looking for a Senior developer to join our team at Mewurk. In this position, you’ll work for product development. We are expecting the below from this role.",
                        "Backend Technologies: .NET Core, Azure Managed services, Event Grid, Service Bus, Elastic Search, Kafka",
                        "Master Coder (Efficient Programming)",
                        "Design one or more business critical Components/Services independently that are Scalable, Secure, has test coverage.", "E2E aspects of compatibility with Devops, logging.",
                        "Operational excellence - APM tools",
                        "Mentors more than one PDE I",
                        "Innovate and easify",
                    ],
                },
                {
                    title: "Team Lead - Frontend",
                    icon: CareerIt,
                    location: "Bangalore",
                    department: "Software Engineering",
                    posted: "June 21, 2024",
                    employment: "Permanent",
                    workMode: "Work from office",
                    openings: 1,
                    experience: "8+ years",
                    qualifications: [
                       "Build pixel-perfect, buttery smooth UIs across both web and mobile platforms.",
                        "Diagnose and fix bugs and performance bottlenecks for performance that feels native.",
                        "Reach out to the open source community to encourage and help implement mission-critical software fixes—React Native moves fast and often breaks things.",
                        "Maintain code and write automated tests to ensure the product is of the highest quality.",
                        "Transition existing React web apps to React Native.",
                    ],
                    skills: [
                       "Firm grasp of the JavaScript and Type Script language and its nuances, including ES6+ syntax",
                        "Knowledge of different front end programming",
                        "Ability to write well-documented, clean Javascript code",
                        "Rock solid at working with third-party dependencies and debugging dependency conflicts",
                        "Familiarity with native build tools, like XCode, Gradle, Android Studio",
                        "Understanding of REST APIs, the document request model, and offline storage",
                        "Experience with automated testing suites",
                    ],
                    jobDescription: [
                        "We are looking for a React Js and Native developer having 8+ years of experience interested in building performant Web apps. You will be responsible for architecting and building these applications, as well as coordinating with the teams responsible for other layers of the product infrastructure. Building a product is a highly collaborative effort, and as such, a strong team player with a commitment to perfection is required.",
                    ]
                },
                {
                    title: "Android Developer",
                    icon: CareerIt,
                    location: "Bangalore",
                    department: "Software Engineering",
                    posted: "June 21, 2024",
                    employment: "Contract",
                    workMode: "Work from Office",
                    openings: 1,
                    experience: "3-5 years",
                    qualifications: [
                        "3-5 years of experience in Android app development.",
                    ],
                    skills: [
                        "Strong understanding of object-oriented programming, Java, Android OS, Sql lite",
                        "Working on Developer tools like Kotlin, Android Studio",
                        "Hands on experience in the full life cycle of Android application development",
                        "Strong understanding of android applications basic - Activities, Services, Intents etc.",
                        "Strong knowledge in multi-screen support design",
                        "Able to solve problems intuitively.",
                    ],
                    jobDescription: [
                        "Design and build advance applications for the Android platform.",
                        "Create, manage, and integrate AAR files for modular and reusable components",
                        "Help maintain code quality, organization, and atomization",
                        "Implement best practices in coding, testing, and use of design patterns",
                        "Identify and fix performance bottlenecks, memory leaks, and other issues to ensure smooth operation of applications",
                        "Work on bug fixing and improving application performance.",
                        "Must be capable of trouble shooting and code enhancement.",
                        "Set up and maintain CI/CD pipelines for Android projects to automate testing, building, and deployment processes",
                        "Debug and resolve issues in JNI integration, ensuring efficient memory and resource management is desired.",
                        "Write and maintain documentation for all features in development."
                    ]
                },
                {
                    title: "Field Sales Executive",
                    icon: CareersMarketing,
                    location: "Ahmedabad",
                    department: "Sales",
                    posted: "June 21, 2024",
                    employment: "Permanent",
                    workMode: "Work from Office",
                    openings: 1,
                    experience: "2-4 years",
                    qualifications: [
                        "Gender: Male",
                        "Age Group: 27-35 years",
                        "Experience: 1.5-2+ years",
                    ],
                    jobDescription: [
                        "Achieve or exceed monthly, quarterly, and annual sales targets.",
                        "Identify and qualify new sales opportunities through cold calling, networking, and other lead generation activities.",
                        "Successfully close sales with new clients, ensuring a high conversion rate.",
                        "Contribute to revenue growth by upselling and cross-selling to existing clients.",
                        "Maintain regular contact with clients to understand their needs, provide support, and ensure customer satisfaction.",
                        "Implement strategies to retain existing clients, minimizing churn rates.",
                        "Gather and relay feedback from clients regarding their experience with the product and service.",
                        "Monitor and report on competitor, activities, including their sales strategies, product features, and market positioning.",
                        "Identify and analyze market trends, customer preferences, and emerging technologies that could impact the HRMS industry.",
                        "Provide actionable insights based on field visits and interactions with clients and prospects.",
                        "Maintain in-depth knowledge of the product, including features, benefits, and use cases.",
                        "Conduct compelling product demonstrations and presentations to potential clients.",
                        "Educate clients on the product&#39;s capabilities and provide necessary training and support during the onboarding process.",
                        "Regularly update and maintain accurate sales records and reports, including pipeline status, sales activities, and progress towards targets.",
                        "Create detailed reports on market conditions, client feedback, and competitive landscape to assist in strategic decision-making.",
                        "Ensure that all interactions and sales activities are accurately logged in the CRM system.",
                        "Work closely with the product development, marketing, and customer support teams to ensure client needs are met and feedback is incorporated into product development.",
                        "Participate in regular sales meetings, sharing insights and strategies with the team to drive collective success.",
                        "Provide strategic input to management based on field experiences and market intelligence.",
                        "Stay updated on industry trends, sales techniques, and product enhancements.",
                        "Attend training sessions and workshops to improve sales skills and product knowledge.",
                    ],
                    skills: [
                        "Evaluation metrics will include the number of new clients acquired, revenue generated from new sales and upsells, sales conversion rate, average deal size, customer satisfaction scores (via surveys or feedback forms), client retention rate, number of product demos conducted, number of competitor insights and market trends reported, timeliness and accuracy of CRM updates, and the frequency and quality of market intelligence reports."
                    ]
                },
                {
                    title: "Telesales Executive",
                    icon: CareersMarketing,
                    location: "Ahmedabad",
                    department: "Sales",
                    posted: "June 21, 2024",
                    employment: "Permanent",
                    workMode: "Work from Office",
                    openings: 1,
                    experience: "1-3 years",
                    qualifications: [
                        "Gender: Female",
                        "Age Group: 25-35 years",
                        "Experience: 1+ years",
                        "Above average fluency in English must"
                    ],
                    jobDescription: [
                        "Generate leads through cold calling, LinkedIn, and other digital and traditional mediums.",
                        "Identify and qualify potential sales opportunities to create a robust sales pipeline.",
                        "Schedule and coordinate appointments and meetings for the field sales team with qualified leads.",
                        "Conduct follow-ups with potential and existing clients to support the sales process and help close sales.",
                        "Maintain regular communication with clients to understand their needs and provide appropriate solutions.",
                        "Develop and maintain strong relationships with new and existing clients to ensure satisfaction and retention.",
                        "Provide detailed information about the HRMS product, including features, benefits, and use cases, to prospective clients.",
                        "Record all interactions and sales activities accurately in the CRM system, ensuring up- to-date and comprehensive records.",
                        "Assist in developing and refining sales scripts and strategies based on feedback and effectiveness.",
                        "Work closely with the field sales team to ensure a seamless handover of qualified lead and support throughout the sales cycle.",
                        "Collect and report feedback from clients and prospects to the sales and product development teams.",
                        "Monitor and report on market trends, customer preferences, and competitor activities to identify new opportunities and threats.",
                        "Participate in regular sales meetings, sharing insights and strategies to drive team success.",
                        "Stay updated on industry trends, sales techniques, and product enhancements to improve performance.",
                        "Attend training sessions and workshops to enhance telesales skills and product knowledge.",
                    ],
                    skills: [
                        "Evaluation metrics will include the number of leads generated, the quality of leads (conversion rate), the number of appointments scheduled, follow-up efficiency, client feedback, customer satisfaction scores, retention rate, accuracy and timeliness of CRM updates, contributions to sales meetings, and proactive engagement in professional development activities."
                    ]
                },
        ];
        setJobs(jobData);
    }, []);

    return (
        <JobContext.Provider value={jobs}>
            {children}
        </JobContext.Provider>
    );
};

export { JobContext, JobProvider };
