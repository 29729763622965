import React, { useState, useEffect } from 'react';
import BlogPost from './BlogCard';
import allBlogsData from './BlogData'
import Pagination from './Pagination';
import { Link } from 'react-router-dom'; 

const BlogList = () => {
  

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);
  const [selectedCategory, setSelectedCategory] = useState('ALL');

  const filteredPosts = allBlogsData.filter(post => {
    return (
      (selectedCategory === 'ALL' || post.category === selectedCategory) &&
      post.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  useEffect(() => {
    setCurrentPage(1); 
  }, [searchTerm, selectedCategory]);

  return (
    <>
    <section className="page-filter p-60">
      <div className="container-fluid">
        <div className="row align-items-center text-white">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h2 className="font-35 bluetxt">Blogs</h2>
          </div>
        </div>
        <div className="row text-white p-60">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div className="cat filtering">
              <h2 className="font-25 black-text">Categories :</h2>
              <div className="categoriesSelect dropdown ml-5">
                <div className="custom-select-wrapper">
                  <select
                    className="select-drop categories-select filter-cat"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                    <option value="ALL">ALL</option>
                    <option value="attendance-management">Attendance Management</option>
                    <option value="leave-management">Leave Management</option>
                    <option value="shift-management">Shift Management</option>
                    <option value="timesheet">Timesheet</option>
                    <option value="onboarding-offboarding">Onboarding and Offboarding</option>
                    <option value="payroll-management">Payroll Management</option>
                    <option value="document-management">Document Management</option>
                    <option value="reports">Reports and Trends</option>
                    <option value="core-hr">Core HR</option>
                </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 wbkitright">
            <div className="form-group font-35 bluetxt search">
            <input
              type="text"
              placeholder="Search"
              className="form-control"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="blog-list p-60">
      <div className="container-fluid">
        <div className="row">
          {currentPosts.map((post, index) => (
            <div className="blog-col f-cat col-sm-12 col-md-6 col-lg-4 mb-30">
                <Link to={`/blog/${post.title.toLowerCase().replace(/\s+/g, '-')}`} className="text-decoration-none text-dark">
                    <BlogPost
                    key={index}
                    title={post.title}
                    imageSrc={post.imageSrc}
                    description={post.description}
                    date={post.date}
                    readingTime={post.readingTime}
                    link={post.link}
                    />
                </Link>
            </div>
          ))}
        </div>
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={filteredPosts.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
    </section>
    </>
  );
};

export default BlogList;
