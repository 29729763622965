// src/pages/NotFoundPage.js
import React from 'react'
import Banner from '../components/common/Banner'
import ComingSoonBannerImage from '../assets/images/comingsoon.png'
import { Helmet } from 'react-helmet';

const ComingSoon = () => {
  return (
    <>
    <Helmet>
      <title>MeWurk</title>
      <meta name="description" content="Best HRMS" />
    </Helmet>
    <Banner 
        title="We Are Working Hard."
        subtitle="Something Awesome Is COMING SOON!"
        imageSrc={ComingSoonBannerImage}
        bgColor="bg-blue"
        paddTopBott="p-60 pb-60"
      />
    </>
  );
};

export default ComingSoon;
