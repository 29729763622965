import React, { useState, useEffect } from 'react';
import BlogPost from './BlogCard';
import allBlogsData from './BlogData';
import { Link } from 'react-router-dom';

function RelatedBlogs() {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);
  const [selectedCategory, setSelectedCategory] = useState('ALL');

  const filteredPosts = allBlogsData.filter(post => {
    return (
      (selectedCategory === 'ALL' || post.category === selectedCategory) &&
      post.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div>
      <section className="related p-60">
        <div className="container-fluid">
          <div className="row align-items-center text-white">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pb-60">
              <h2 className="font-25 bluetxt">Related Blogs</h2>
            </div>
              {currentPosts.slice(0, 3).map((post) => (
                <div key={post.id} className="blog-col f-cat col-sm-12 col-md-6 col-lg-4 mb-30">
                    <Link to={`/blog/${post.title.toLowerCase().replace(/\s+/g, '-')}`} className="text-decoration-none text-dark">
                        <BlogPost
                        title={post.title}
                        imageSrc={post.imageSrc}
                        description={post.description}
                        date={post.date}
                        readingTime={post.readingTime}
                        link={post.link}
                        />
                    </Link>
                </div>
              ))}
            </div>
          </div>
      </section>
    </div>
  );
}

export default RelatedBlogs;
