import React from 'react';
import prev from '../../../assets/images/blue-prev.svg'
import next from '../../../assets/images/blue-next.svg'

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <section className="pagination-list pt-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
            <nav className="text-center">
              <ul className="pagination justify-content-center">
                {/* Previous button */}
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>
                    <img src={prev} alt="Previous" />
                  </a>
                </li>

                {/* Page numbers */}
                {pageNumbers.map(number => (
                  <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                    <a onClick={() => paginate(number)} className="page-link" href="#">
                      {number}
                    </a>
                  </li>
                ))}

                {/* Next button */}
                <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                  <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>
                  <img src={next} alt="Previous" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pagination;
