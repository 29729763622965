// src/pages/NotFoundPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import NotFoundImg from '../assets/images/notfound.png'
import { Helmet } from 'react-helmet';

const NotFoundPage = () => {
  return (
    <>
    <Helmet>
        <title>MeWurk</title>
        <meta name="description" content="Best HRMS" />
    </Helmet>
    <section className="notfound p-30">
        <div className="container-fluid">
            <div className="row align-items-center text-white">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="inner-banner text-center">
                        <img src={NotFoundImg} alt="NotFound" className="img-fluid" />
                        <h1 className="font-35 bluetxt">Sorry, we couldn't find this page</h1>
                        <h3 className="font-n25 text-dark mt-30"> But don't worry, you can find plenty of other<br /> things on our website</h3>
                        <div className="action-inner-c2a">
                            <div className="btns-div">
                                <Link to="/" className="btn-join text-white btn-blue l-btns px-5">Go Home</Link>
                                <Link to="/contact-us.html" className="btn-join l-btns text-white btn-orange px-5">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  );
};

export default NotFoundPage;
