import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const CanonicalLink = () => {
  const location = useLocation();
  const canonicalUrl = `https://www.mewurk.com${location.pathname}`;

  return (
    process.env.REACT_APP_ENVIRONMENT === 'production' && (
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
    )
  );
};

export default CanonicalLink;
