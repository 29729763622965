import React from 'react'
import Banner from '../components/common/Banner'
import BannerImage from '../assets/images/blog-banner.png'
import blogCalltoImg from '../assets/images/blog-callto.png';
import BlogList from '../components/common/Blog/BlogList';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const CallToAction = ({ handleShowEarlyAccessModal }) => {
    return (
      <>
        <Helmet>
          <title>Mewurk  Blog |  HR Software Solutions Blog</title>
          <meta name="description" content="Explore the Mewurk Blog for the latest insights and trends in HR management. Stay informed with expert advice, tips, and articles on workforce solutions" />
        </Helmet>
        <section className="c2a p-60">
          <div className="container-fluid">
            <div className="row align-items-center bg-blue">
              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7">
                <h1 className="font-25 mt-30">Ready to give it a try?</h1>
                <p className="font-20 text-white mt-30 mb-4">
                  The World’s Simplest productivity solution, that<br />
                  saves a lot of time and cost.
                </p>
                <Link onClick={handleShowEarlyAccessModal} className="btn-orange btn-join white-bg-bluetext">
                  Register for Early Access Program
                </Link>
                <ul className="tick-list font-20 text-white mt-4">
                  <li>Get yourself on waitlist for FREE access</li>
                  <li>Onboard your company in an Hour</li>
                </ul>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                <img src={blogCalltoImg} alt="c2a" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

const Blog = ({handleShowEarlyAccessModal}) => {
  return (
    <div>
        <Banner 
            title="Mewurk Blog - Dive Into Expert Insights"
            subtitle="Stay Updated: What's New in Workforce Management and Our Software? Ensure your business is always one step ahead of the competition."
            imageSrc={BannerImage}
            bgColor="bg-blue"
            paddTopBott="p-60 pb-60"
        />
        <BlogList />
        <CallToAction  
        handleShowEarlyAccessModal={handleShowEarlyAccessModal}
        />
    </div>
  )
}

export default Blog
