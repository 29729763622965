import React from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import fbIcon from '../../../assets/images/fb.svg';
import xIcon from '../../../assets/images/blog-x.svg';
import linkedInIcon from '../../../assets/images/blog-ldin.svg';
import whatsappIcon from '../../../assets/images/blog-wp.svg';
import instagramIcon from '../../../assets/images/blog-insta.svg';
import rightArrowOrange from '../../../assets/images/right-arrow-orange.svg';
import allBlogsData from './BlogData'; 
import {constant} from '../constant/constant';

const ShareArticle = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const currentUrl = window.location.href;

  const currentPostIndex = allBlogsData.findIndex(post => post.id === parseInt(postId));
  const nextPost = currentPostIndex >= 0 && currentPostIndex < allBlogsData.length - 1
    ? allBlogsData[currentPostIndex + 1]
    : null;

    const shareOnFacebook = () => {
      window.open(`${constant.FACEBOOK_SHARE_URL}${currentUrl}`, '_blank');
    };
  
    const shareOnTwitter = () => {
      window.open(`${constant.TWITTER_SHARE_URL}${currentUrl}`, '_blank');
    };
  
    const shareOnLinkedIn = () => {
      window.open(`${constant.LINKEDIN_SHARE_URL}${currentUrl}`, '_blank');
    };
  
    const shareOnWhatsApp = () => {
      window.open(`${constant.WHATSAPP_SHARE_URL}${currentUrl}`, '_blank');
    };
  
    const shareOnInstagram = () => {
      window.open(constant.INSTAGRAM_URL, '_blank');
    };

  const goToNextPost = () => {
    if (nextPost) {
      navigate(nextPost.url);
    }
  };

  return (
    <>
      <hr />
      <div className="shareArticle">
        <div className="shareSocial">
          <h3 className="socialTitle">Share this Article</h3>
          <ul className="socialList">
            <li onClick={shareOnFacebook}>
              <Link to="#">
                <img src={fbIcon} className="img-fluid" alt="fb" />
              </Link>
            </li>
            <li onClick={shareOnTwitter}>
              <Link to="#">
                <img src={xIcon} className="img-fluid" alt="x" />
              </Link>
            </li>
            <li onClick={shareOnLinkedIn}>
              <Link to="#">
                <img src={linkedInIcon} className="img-fluid" alt="linkedin" />
              </Link>
            </li>
            <li onClick={shareOnWhatsApp}>
              <Link to="#">
                <img src={whatsappIcon} className="img-fluid" alt="whatsapp" />
              </Link>
            </li>
            <li onClick={shareOnInstagram}>
              <Link to="#">
                <img src={instagramIcon} className="img-fluid" alt="instagram" />
              </Link>
            </li>
          </ul>
          <div className="next-post text-right">
            {nextPost && (
              <Link to="#" onClick={goToNextPost} className="font-20 orangetxt">
                Next Post <img src={rightArrowOrange} className="img-fluid" alt="icon-next" />
              </Link>
            )}
          </div>
        </div>
      </div>
      <hr />
    </>
  );
};

export default ShareArticle;
