import React from 'react';
import landingRating from '../../../assets/images/landing-rating.svg';
import FormSection from './Form';

function BannerSection() {
  return (
    <section className="banner">
      <div className="banner-image">
        <div className="container-fluid py-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banner-left-content">
                <h4 className="text-white banner-main-heading">Tired of doing HR tasks manually? </h4>
                <h5 className="yellowtxt font-25 padding-25">Skyrocket the productivity and efficiency of your workforce for FREE.</h5>
                <ul className="listol p-0 text-white">
                  <li>Onboard your company under 15 mins </li>
                  <li>24x7 free support </li>
                  <li>More than 40+ reports</li>
                  <li>Get an AI-enabled face recognition device </li>
                  <li>No payment for 1 year under EAP</li>
                </ul>
                <h6 className="font-25 text-white">Beta customers Rated</h6>
                <p className="ratingtxt text-white">
                  <img src={landingRating} className="img-fluid" alt="rating" />
                  <span className="position-absolute mt-1 ml-2">Rated 4.4</span>
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <FormSection />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BannerSection;
