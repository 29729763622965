// src/components/AppContent.js
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import ScrollToTopOnRefresh from './common/ScrollToTopOnRefresh';
import CanonicalLink from '../routes/CanonicalLink';
import MainRoutes from '../routes';
import Modals from './common/Modal/Modals';
import usePageTracking from '../hooks/usePageTracking';

const AppContent = () => {
  const location = useLocation();

  usePageTracking();

  const noHeaderFooterRoutes = [
    '/attendance-workforce-management-software',
    '/time-attendance-system-lp1',
    '/time-attendance-software-ahmedabad-lp2',
  ];

  const [showEarlyAccessModal, setShowEarlyAccessModal] = useState(false);
  const [showDemoRequestModal, setShowDemoRequestModal] = useState(false);
  const [showTryNowModal, setShowTryNowModal] = useState(false);

  const handleCloseEarlyAccessModal = () => setShowEarlyAccessModal(false);
  const handleCloseDemoRequestModal = () => setShowDemoRequestModal(false);
  const handleCloseTryNowModal = () => setShowTryNowModal(false);

  const handleShowEarlyAccessModal = () => setShowEarlyAccessModal(true);
  const handleShowDemoRequestModal = () => setShowDemoRequestModal(true);
  const handleShowTryNowModal = () => setShowTryNowModal(true);

  return (
    <>
      <CanonicalLink />
      {!noHeaderFooterRoutes.includes(location.pathname) && (
        <Header handleShowEarlyAccessModal={handleShowEarlyAccessModal} handleShowDemoRequestModal={handleShowDemoRequestModal} />
      )}
      <ScrollToTopOnRefresh />
      <MainRoutes handleShowEarlyAccessModal={handleShowEarlyAccessModal} handleShowDemoRequestModal={handleShowDemoRequestModal} handleShowTryNowModal={handleShowTryNowModal} />
      {!noHeaderFooterRoutes.includes(location.pathname) && <Footer />}
      <Modals 
        showEarlyAccessModal={showEarlyAccessModal}
        handleCloseEarlyAccessModal={handleCloseEarlyAccessModal}
        showDemoRequestModal={showDemoRequestModal}
        handleCloseDemoRequestModal={handleCloseDemoRequestModal}
        showTryNowModal={showTryNowModal}
        handleCloseTryNowModal={handleCloseTryNowModal}
      />
    </>
  );
};

export default AppContent;
