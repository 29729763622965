import React from 'react';
import thankyouImg from "../../../assets/images/thank-you.svg"
import { Link } from 'react-router-dom';

const ThankYouModal = ({ onClose }) => {
  return (
    <div className="modal-content">
      <div className="modal-body text-center">
        <Link to="#quick-form" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </Link>
        <div className="thankdiv py-5">
          <img src={thankyouImg} alt="thankyou" className="img-fluid" />
          <h4 className="bluetxt font-25 pt-4 pb-2">Thank You</h4>
          <p>
            Your submission has been received.<br />
            We will be in touch and contact you soon!
          </p>
        </div>
      </div>
    </div>
  );
};

export default ThankYouModal;
