import React from 'react';
import thankyouImg from "../../../assets/images/thank-you.svg"
import { Link } from 'react-router-dom';

const EmailSubscribeModal = ({ onClose }) => {
  return (
    <div className="modal-content">
      <div className="modal-body text-center">
        <Link to="#quick-form" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </Link>
        <div className="thankdiv py-5">
          <img src={thankyouImg} alt="thankyou" className="img-fluid" />
          <h4 className="bluetxt font-25 pt-4 pb-2">Thank You</h4>
          <p>
            Thanks! It looks like you are booked.<br />
            Our team is here to help you with a quick 30 mins demo.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailSubscribeModal;
