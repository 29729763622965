import axios from 'axios';

// Environment variables
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const CODE_PARAMETER = API_KEY; 

if (!API_BASE_URL) {
  throw new Error('API_BASE_URL is not defined');
}

if (!API_KEY) {
  throw new Error('API_KEY is not defined');
}

// Define API paths
const DEMO_REQUEST_API_PATH = process.env.REACT_APP_DEMO_REQUEST_API_PATH;
const EARLY_ACCESS_API_PATH = process.env.REACT_APP_EARLY_ACCESS_API_PATH;
const TRY_NOW_API_PATH = process.env.REACT_APP_TRY_NOW_API_PATH;
const CAREERS_API_PATH = process.env.REACT_APP_CAREERS_API_PATH;
const PARTNER_WITH_US_API_PATH = process.env.REACT_APP_PARTNER_WITH_US_API_PATH;
const EMAIL_SUBSCRIBER_API_PATH = process.env.REACT_APP_EMAIL_SUBSCRIBER_API_PATH;

// Create API URLs
const createApiUrl = (path) => `${API_BASE_URL}${path}`;

const DEMO_REQUEST_API_URL = createApiUrl(DEMO_REQUEST_API_PATH);
const EARLY_ACCESS_API_URL = createApiUrl(EARLY_ACCESS_API_PATH);
const TRY_NOW_API_URL = createApiUrl(TRY_NOW_API_PATH);
const CAREERS_API_URL = createApiUrl(CAREERS_API_PATH);
const PARTNER_WITH_US_API_URL = createApiUrl(PARTNER_WITH_US_API_PATH);
const EMAIL_SUBSCRIBER_API_URL = createApiUrl(EMAIL_SUBSCRIBER_API_PATH);

// Set up Axios instance with interceptors
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json'
  },
  params: {
    'code': CODE_PARAMETER  // Include the 'code' parameter in every request
  }
});

// Add interceptors
api.interceptors.request.use(
  (config) => config,
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('Response error:', error.response ? error.response.data : error.message);
    return Promise.reject(error);
  }
);

// API functions
const submitRequest = (url, values) => api.post(url, values);

export const submitDemoRequest = (values) => submitRequest(DEMO_REQUEST_API_URL, values);
export const submitEarlyAccessRequest = (values) => submitRequest(EARLY_ACCESS_API_URL, values);
export const submitTryNowRequest = (values) => submitRequest(TRY_NOW_API_URL, values);
export const submitCareers = (values) => submitRequest(CAREERS_API_URL, values);
export const submitPartner = (values) => submitRequest(PARTNER_WITH_US_API_URL, values);
export const submitBlogAlertRequest = (values) => submitRequest(EMAIL_SUBSCRIBER_API_URL, values);

export default api;
