import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ThankYouModal from './ThankYouModal';
import useFormValidation from '../../../hooks/useFormValidation';
import validate from '../../../hooks/validate';
import { submitDemoRequest } from '../../../services/apiService';
import handleFormSubmit from '../constant/handleSubmit';

const DemoRequestModal = ({ show, handleClose }) => {
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const submitForm = async (values, resetForm) => {
    await handleFormSubmit(
      submitDemoRequest,
      values,
      setShowThankYouModal,
      resetForm,
      () => {}, 
      setIsSubmitting,
      setSubmitError
    );
    handleClose(); 
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    resetForm,
  } = useFormValidation(
    { firstName: '', email: '', phone: '' },
    (values) => validate(values, 'demoRequest'),
    (values) => submitForm(values, resetForm) 
  );

  // Reset form when modal is closed
  const handleCloseModal = () => {
    handleClose();
    resetForm();
  };

  return (
    <>
      <Modal show={show} onHide={handleCloseModal} centered>
        <Modal.Body>
          <button className="close" onClick={handleCloseModal} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="form-div" id="quick-form">
            <form id="bookFreeDemo" onSubmit={handleSubmit}>
              <h2 className="text-center banner-form-heading bluetxt">Request a Demo</h2>
              <p className="text-center formh-txt bluetxt">It's easier and faster - a single platform that addresses all your workplace needs.</p>
              <div className="form-group mb-4">
                <input
                  type="text"
                  name="firstName"
                  id="name"
                  placeholder="Name*"
                  className={`form-control ${errors.firstName && 'is-invalid'}`}
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.firstName && <p className="error">{errors.firstName}</p>}
              </div>
              <div className="form-group mb-4">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email*"
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
              <div className="form-group mb-4">
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Phone Number*"
                  className={`form-control ${errors.phone && 'is-invalid'}`}
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.phone && <p className="error">{errors.phone}</p>}
              </div>
              <div className="joinbtn-div">
                <button className="btn-blue btn-join" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Submitting...' : 'Send Message'}
                </button>
                {submitError && <p className="error">{submitError}</p>}
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showThankYouModal} onHide={() => setShowThankYouModal(false)} centered>
        <ThankYouModal onClose={() => setShowThankYouModal(false)} />
      </Modal>
    </>
  );
};

export default DemoRequestModal;
