import React from 'react'
import logo from '../../assets/images/logo.svg';
import ratingImg from '../../assets/images/landing-rating.svg';
import Betag from '../../assets/images/betag.svg';
import fbIcon from '../../assets/images/fb-icon.svg';
import twitterIcon from '../../assets/images/twitter-icon.svg';
import linkedinIcon from '../../assets/images/linkedin-icon.svg';
import mailsmallicon from '../../assets/images/mailsmallicon.png';
import callsmallicon from '../../assets/images/callsmallicon.png';
import FormWithAllDetails from '../../components/common/LandingPage/FormWithAllDetails';
import { Link } from 'react-router-dom';
import {constant} from '../../components/common/constant/constant';



const LandingPageTwo = () => {
  return (
    <div>
        <section className="header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 text-left mobile-left">
                        <Link to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 text-right mobile-right">
                        <p>
                            <Link to={`mailto:${constant.emailId}`} className="bluetxt font-20 mr-2">
                                <img src={mailsmallicon} alt="mailsmallicon" className="img-fluid" /> {constant.emailId}
                            </Link>
                            <Link to={`tel:${constant.phoneNumber}`} className="bluetxt font-20 ml-2">
                                <img src={callsmallicon} alt="mailsmallicon" className="img-fluid" />  {constant.phoneNumber}
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section className="banner">
            <div className="landing-banner-image">
                <div className="container-fluid py-5">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="banner-left-content">
                                <h5 className="yellowtxt2 font-30">Ahmedabad's latest & Simplest</h5>
                                <h1 className="text-white font-35px">Attendance & Employee<br/> management software.</h1>
                                <p className='font-20 text-white mt-20'>A complete hardware and software combo<br /> that will automate Attendance, Leaves, and<br /> shift in minutes</p>
                                <ul className="p-0 text-white blue-list btick font-n20 mt-30 mb-60">
                                    <li className="pt-4"> FREE for Ahmedabad startups & SMEs -<br /> <span className='yellowtxt2'>On first come basis</span></li>
                                    <li className="pt-4"> Backed by <span className='yellowtxt2'>Mantra Softech</span> - Leader in <br /> Biometrics & Identity solutions</li>
                                </ul>
                                <div className="align-item-center">
                                <p className="ratingtxt text-white">
                                    <img src={Betag} className="img-fluid mt-3" alt="BetaG" />
                                    <span className="position-absolute mt-1 ml-4 font-16">
                                        <img src={ratingImg} className="img-fluid" alt="ratingImg" /><br/>Rated 4.8 average ratings on global platforms</span>
                                </p>
                                </div>
                            </div>
                        </div>
                        <FormWithAllDetails />
                    </div>
                </div>
            </div>
        </section>
        <section className="footer bg-gray ptb-20">
            <div className="container-fluid">
                <div className="row footer-bottom">
                    <div className="col-md-8 text-white">
                        <h2 className="font-18 text-dark m-0">Copyright © 2024. Mewurk Technologies Pvt. Ltd. All Rights Reserved.</h2>
                    </div>
                    <div className="col-md-4 text-white text-right">
                        <p className="font-18 text-dark m-0 socialp"><span className="mr-2">Connect with us :</span> 
                            <Link to={constant.mewurkfb} target="_blank" rel="noopener noreferrer">
                                <img src={fbIcon} className="img-fluid m-1" alt="fb" />
                            </Link>
                            <Link to={constant.mewurktwitter} target="_blank" rel="noopener noreferrer">
                                <img src={twitterIcon} className="img-fluid m-1" alt="twitter" />
                            </Link>
                            <Link to={constant.mewurklinkedin} target="_blank" rel="noopener noreferrer">
                                <img src={linkedinIcon} className="img-fluid m-1" alt="linkedin" />
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default LandingPageTwo
