export const constant = {
  mewurkfb:"https://www.facebook.com/mewurk/",
  mewurkinsta:"https://www.instagram.com/mewurktechnologies/",
  mewurklinkedin:"https://www.linkedin.com/company/mewurk/",
  mewurktwitter:"https://twitter.com/mewurk77237",

  FACEBOOK_SHARE_URL:'https://www.facebook.com/sharer/sharer.php:=',
  TWITTER_SHARE_URL: 'https://twitter.com/intent/tweet?url=',
  LINKEDIN_SHARE_URL: 'https://www.linkedin.com/shareArticle?url=',
  WHATSAPP_SHARE_URL: 'https://api.whatsapp.com/send?text=',
  INSTAGRAM_URL: 'https://www.instagram.com',

  hiren: "https://www.linkedin.com/in/hirenbhandari/",
  roshan: "https://www.linkedin.com/in/roshan-joseph1/",
  bipin: "https://www.linkedin.com/in/kumarbipin/",
  sujay: "https://www.linkedin.com/in/sujayk/",
  kaushik: "https://www.linkedin.com/in/kaushik-vatsa-4582536/",
  linu: "https://www.linkedin.com/in/linukoshy/",
  // team
  Harnish:	"https://www.linkedin.com/in/harnish-prajapati-36290013/",
  Navnit:	"https://www.linkedin.com/in/navnit-vadi-38816216b/",
  Sheetal:	"https://www.linkedin.com/in/sheetal-rasgon/",
  Sonal:	"https://www.linkedin.com/in/sonal-kamat-03a383a9/",
  Nivesh:	"https://www.linkedin.com/in/nivesh-nadarajan-84aa0313b/",
  Balasubrahmanyam:	"https://www.linkedin.com/in/balasubrahmanyam-tekimudi-078571160/",
  Pradnya:	"https://www.linkedin.com/in/pradnya-g-4bb222172/",
  Kiran:	"https://www.linkedin.com/in/kiran-pathak-a13bb910a/",
  Chaitra:	"https://www.linkedin.com/in/chaitra-p-499b46124/",
  Kasala:	"https://www.linkedin.com/in/devendar-kasala-9a6549231/",
  Palagala:	"https://www.linkedin.com/in/devi-palagala-b01179239/",
  Sahil:	"https://www.linkedin.com/in/sahilxt/",
  Sachin:	"https://www.linkedin.com/in/sachin-kotian-75162314b/",
  Mansi:	"https://www.linkedin.com/in/mansi-kansara-b0390a129/",
  Lingeshan:	"https://www.linkedin.com/in/lingeshanj/",
  Anto:	"https://www.linkedin.com/in/anto-francis-9a03371b4/",
  Vrinda:	"https://www.linkedin.com/in/vrinda-nair/",
  Aryan:	"https://www.linkedin.com/in/aryanvaish/",
  Saudamini:	"https://www.linkedin.com/in/saudaminidesh/",
  Mayur:	"https://www.linkedin.com/in/mayur-dangi-b755b5221/",
  Praveen:	"https://www.linkedin.com/in/praveen-murali-6ba3421b4/",
  Gnanavardhan :	"https://www.linkedin.com/in/gnana-vardhan-914028184/",
  Raghu:	"https://www.linkedin.com/in/raghuram-madiga/",
  Bharath:	"https://www.linkedin.com/in/bharath-teja-09884b178/",
  Vaibhav:	"https://www.linkedin.com/in/vaibhav-kulkarni-017807232/",
  Ashok:	"https://www.linkedin.com/in/ashok-kumar-605540253/",
  Kotramma:	"https://www.linkedin.com/in/kotramma-g-6b7757285/",
  Ajay:	"https://www.linkedin.com/in/ajay-karan/",
  Dhanraj:	"https://www.linkedin.com/in/dhanrajgullapalli25/",
  Chaitanya:	"https://www.linkedin.com/in/chaitanya-pramodh-390625285/",
  Naveen:	"https://www.linkedin.com/in/naveen-kumar-d-594b97237/",
  Ashwin:	"https://www.linkedin.com/in/ashwin-girish-895276157/",
  Lucy:	"https://www.linkedin.com/in/lucy-varshitha-bellam-292899191/",
  Trilok:	"https://www.linkedin.com/in/trilok-chand-thakur-a9b984192/",
  Sravani:	"https://www.linkedin.com/in/s-sravani-7158b0213/",
  Manisha:	"https://www.linkedin.com/in/byalla-manisha-2b5bb924a/",
  Mounika:	"https://www.linkedin.com/in/mounika-boggula-1a402923b/",
  Swathi:	"https://www.linkedin.com/in/kavali-swathi-194195241/",
  Yogendra:	"https://www.linkedin.com/in/yogendra-yasarapu-a71ab9250/",
  Chetankumar:	"https://www.linkedin.com/in/chetan-patel-558332129/",
  Aravindh:	"https://www.linkedin.com/in/aravindh-j-55a764251/",
  Jeet:	"https://www.linkedin.com/in/jeet-goyal-2836b7298/",
  Linu:	"https://www.linkedin.com/in/linukoshy/",
  Dhrumil:	"https://www.linkedin.com/in/dhrumil-punjabi-450a34221/",
  Sagar:	"https://www.linkedin.com/in/sahejad/",
  Dhruv:	"https://www.linkedin.com/in/dhruv-joshi-890453240/",
  Shailesh:	"https://www.linkedin.com/in/shailesh-dhapa-b83116146/",
  Nisha:	"https://www.linkedin.com/in/nisha-patel-427ba2192/",
// BhanuKumar Muppalla	
// Ankireddypalli Sravani	
// Vivek	
// Sweety	
// Chaitanya	
// Faisal	
// Umesh	
// Ashribad Samal	
// J Vijay Kumar	

  phoneNumber: "+91-9512326261",
  phoneNumberSupport: "+91-9512322076",
  emailId: "sales@mewurk.com",
  emailIdSupport: "support@mewurk.com",

};
// Form Messages
export const errors= {
  enterEmail: 'Please enter your email',
  validEmail: 'Please enter a valid email',
  enterPhone: 'Please enter your phone number',
  validPhone: 'Invalid Input, Please Enter Only 10 Digit Phone Number',
  enterFirstName: 'Please enter your first name',
  enterLastName: 'Please enter your last name',
  uploadResume: 'Please upload your resume',
  enterName: 'Please enter your name',
  enterSubject: 'Please enter a Subject',
  writeMessage: 'Please write a message',
  selectEmployees: 'Please select number of employees',
  enterFullName: 'Please enter your full name',
  enterBusinessEmail: 'Please enter your business email',
  enterCompanyName: 'Please enter your company name',
  enterLocation: 'Please enter your location',
  emailAlreadySubscribed: 'This email is already subscribed.',
  generalSubmitError: 'An error occurred while submitting the form.',
  networkError: 'Network error: Please check your internet connection.',
  fileTypeError: 'Invalid file type. Please upload a PDF, DOC, DOCX, TXT, or RTF file.',
}
export const errorfile ={fileSizeError: 'File size exceeds 5MB. Please upload a smaller file.',}