import { submitBlogAlertRequest, submitCareers, submitTryNowRequest, submitPartner } from '../../../services/apiService';
import { errors } from './constant';

const handleFormSubmit = async (
  submitFunction,
  values,
  setShowThankYouModal,
  resetForm,
  resetFileHandler,
  setIsSubmitting,
  setSubmitError
) => {
  console.log('Submitting Form:', values);
  try {
    setIsSubmitting(true);
    await submitFunction(values);
    setShowThankYouModal(true);
    resetForm();
    resetFileHandler();
    setTimeout(() => {
      setShowThankYouModal(false);
    }, 6000);
  } catch (error) {
    console.error('Error submitting form data:', error);
    if (error.response) {
      if (error.response.status === 500) {
        setSubmitError(errors.emailAlreadySubscribed);
      } else {
        setSubmitError(errors.generalSubmitError);
      }
    } else {
      setSubmitError(errors.networkError);
    }
  } finally {
    setIsSubmitting(false);
  }
};

export default handleFormSubmit;

export const handleBlogSubmit = async (
  values,
  setIsSubmitting,
  setShowThankYouModal,
  resetForm,
  setSubmitError
) => {
  await handleFormSubmit(
    submitBlogAlertRequest,
    values,
    setShowThankYouModal,
    resetForm,
    () => {}, 
    setIsSubmitting,
    setSubmitError
  );
};

export const onSubmitCareers = async (
  values,
  attachmentBase64,
  setShowThankYouModal,
  resetForm,
  resetFileHandler
) => {
  await handleFormSubmit(
    submitCareers,
    { ...values, attachmentFile: attachmentBase64 },
    setShowThankYouModal,
    resetForm,
    resetFileHandler,
    () => {}, 
    () => {} 
  );
};

export const onSubmitPartner = async (
  values,
  setShowThankYouModal,
  resetForm,
  setIsSubmitting,
  setSubmitError
) => {
  await handleFormSubmit(
    submitPartner,
    values,
    setShowThankYouModal,
    resetForm,
    () => {}, 
    setIsSubmitting,
    setSubmitError
  );
};

export const onSubmitTryNow = async (
  values,
  setShowThankYouModal,
  resetForm,
  setIsSubmitting,
  setSubmitError
) => {
  await handleFormSubmit(
    submitTryNowRequest,
    values,
    setShowThankYouModal,
    resetForm,
    () => {}, 
    setIsSubmitting,
    setSubmitError
  );
};