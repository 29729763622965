import React, { useState } from 'react';
import useFormValidation from '../../../hooks/useFormValidation';
import validate from '../../../hooks/validate';
import { submitEarlyAccessRequest } from '../../../services/apiService';
import { Modal } from 'react-bootstrap';
import ThankYouModal from '../Modal/ThankYouModal';
import handleFormSubmit from '../constant/handleSubmit';

const FormWithAllDetails = () => {
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitForm = async (values) => {
    await handleFormSubmit(
      submitEarlyAccessRequest,
      values,
      setShowThankYouModal,
      resetForm,
      () => {}, 
      setIsSubmitting,
      setSubmitError
    );
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    resetForm,
  } = useFormValidation(
    { firstName: '', email: '', phone: '', companyName: '', numberOfEmployeess: '' },
    (values) => validate(values, 'landingPageForm'),
    submitForm
  );

  return (
    <div className="col-md-5">
      <div className="form-div form-landing-banner" id="quick-form">
        <form id="myForm" onSubmit={handleSubmit}>
          <h2 className="text-center font-25 bluetxt mb-30">Your FREE Subscription Awaits</h2>
          <div className="form-group">
            <label htmlFor="fullname">Full Name<span className="text-danger">*</span></label>
            <input
              type="text"
              id="fullname"
              name="firstName"
              className={`form-control ${errors.firstName && 'is-invalid'}`}
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.firstName && <p className="error">{errors.firstName}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Business Email<span className="text-danger">*</span></label>
            <input
              type="email"
              id="email"
              name="email"
              className={`form-control ${errors.email && 'is-invalid'}`}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.email && <p className="error">{errors.email}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number<span className="text-danger">*</span></label>
            <input
              type="text"
              id="phone"
              name="phone"
              className={`form-control ${errors.phone && 'is-invalid'}`}
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.phone && <p className="error">{errors.phone}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="companyName">Company Name<span className="text-danger">*</span></label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              className={`form-control ${errors.companyName && 'is-invalid'}`}
              value={values.companyName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.companyName && <p className="error">{errors.companyName}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="employee_count">Numbers of Employee<span className="text-danger">*</span></label>
            <select
              id="employee_count"
              name="numberOfEmployeess"
              className={`form-control ${errors.numberOfEmployeess && 'is-invalid'}`}
              value={values.numberOfEmployeess}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Please Select</option>
              <option>1-50</option>
              <option>51-100</option>
              <option>101-200</option>
              <option>201-500</option>
            </select>
            {errors.numberOfEmployeess && <p className="error">{errors.numberOfEmployeess}</p>}
          </div>
          <p className="small-tctxt">By providing information, you agree to our <b><u>T&C</u></b> and <b><u>Privacy Policy</u></b>.</p>
          <div className="joinbtn-div">
            <button className="btn-blue w-100 btn-join" id="submit" type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Get Now'}
            </button>
            {submitError && <p className="error">{submitError}</p>}
          </div>
        </form>
        <Modal
          show={showThankYouModal}
          onHide={() => setShowThankYouModal(false)}
          centered
        >
          <ThankYouModal onClose={() => setShowThankYouModal(false)} />
        </Modal>
      </div>
    </div>
  );
};

export default FormWithAllDetails;
