// src/components/Modals.js
import React from 'react';
import DemoRequestModal from './DemoRequestModal';
import TryNowModal from './TryNowModal';
import EarlyAccessModal from './EarlyAccessModal';

const Modals = ({ showEarlyAccessModal, handleCloseEarlyAccessModal, showDemoRequestModal, handleCloseDemoRequestModal, showTryNowModal, handleCloseTryNowModal }) => (
  <>
    <EarlyAccessModal show={showEarlyAccessModal} handleClose={handleCloseEarlyAccessModal} />
    <DemoRequestModal show={showDemoRequestModal} handleClose={handleCloseDemoRequestModal} />
    <TryNowModal show={showTryNowModal} handleClose={handleCloseTryNowModal} />
  </>
);

export default Modals;
