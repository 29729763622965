import React, { lazy, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Loader from '../assets/images/loader.gif';
import NotFoundPage from '../pages/NotFoundPage';
import ComingSoon from '../pages/ComingSoon';
import BlogDetail from '../pages/BlogDetail';
import Blog from '../pages/Blog';
import AttendanceWorkforceManagementSoftware from '../pages/LandingPages/AttendanceWorkforceManagementSoftware';
import LandingPage from '../pages/LandingPages/LandingPage';
import LandingPageTwo from '../pages/LandingPages/LandingPageTwo';

const Home = lazy(() => import('../pages/Home'));
const OurStory = lazy(() => import('../pages/OurStory'));
const OurTeam = lazy(() => import('../pages/OurTeam'));
const Partner = lazy(() => import('../pages/Partner'));
const Careers = lazy(() => import('../pages/Careers'));
const CareersDetail = lazy(() => import('../pages/CareersDetail'));
const ContactUs = lazy(() => import('../pages/ContactUs'));
const HrGlossary = lazy(() => import('../pages/HrGlossary'));
const TouchlessAttendance = lazy(() => import('../pages/TouchlessAttendance'));
const MobileApp = lazy(() => import('../pages/MobileApp'));
const AttendanceManagement = lazy(() => import('../pages/AttendanceManagement'));
const LeaveManagement = lazy(() => import('../pages/LeaveManagement'));
const ShiftManagement = lazy(() => import('../pages/ShiftManagement'));
const DocumentManagement = lazy(() => import('../pages/DocumentManagement'));
const ReportsTrends = lazy(() => import('../pages/ReportsTrends'));
const CoreHr = lazy(() => import('../pages/CoreHr'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const RefundPolicy = lazy(() => import('../pages/RefundPolicy'));
const TermsConditions = lazy(() => import('../pages/TermsConditions'));

const MainRoutes = ({ handleShowEarlyAccessModal, handleShowDemoRequestModal, handleShowTryNowModal }) => (
  <Suspense fallback={<div className="text-center"><img src={Loader} alt='loader' /></div>}>
    
    <Routes>
      <Route path="/" exact element={<Home handleShowEarlyAccessModal={handleShowEarlyAccessModal} handleShowDemoRequestModal={handleShowDemoRequestModal} handleShowTryNowModal={handleShowTryNowModal} />} />
      <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
      <Route path="/terms-conditions" exact element={<TermsConditions />} />
      <Route path="/refund-policy" exact element={<RefundPolicy />} />
      <Route path="/mewurk-story" exact element={<OurStory />} />
      <Route path="/our-team" exact element={<OurTeam />} />
      <Route path="/mewurk-partners" exact element={<Partner />} />
      <Route path="/careers" exact element={<Careers />} />
      <Route path="/careers-detail" exact element={<CareersDetail />} />
      <Route path="/contact-us" exact element={<ContactUs />} />
      <Route path="/hr-glossary" exact element={<HrGlossary handleShowEarlyAccessModal={handleShowEarlyAccessModal} handleShowDemoRequestModal={handleShowDemoRequestModal} handleShowTryNowModal={handleShowTryNowModal} />} />
      <Route path="/biometric-touchless-attendance" exact element={<TouchlessAttendance handleShowEarlyAccessModal={handleShowEarlyAccessModal} handleShowDemoRequestModal={handleShowDemoRequestModal} handleShowTryNowModal={handleShowTryNowModal} />} />
      <Route path="/mobile-application" exact element={<MobileApp handleShowEarlyAccessModal={handleShowEarlyAccessModal} handleShowDemoRequestModal={handleShowDemoRequestModal} handleShowTryNowModal={handleShowTryNowModal} />} />
      <Route path="/attendance-management-system" exact element={<AttendanceManagement handleShowEarlyAccessModal={handleShowEarlyAccessModal} handleShowDemoRequestModal={handleShowDemoRequestModal} handleShowTryNowModal={handleShowTryNowModal} />} />
      <Route path="/leave-management-system" exact element={<LeaveManagement handleShowEarlyAccessModal={handleShowEarlyAccessModal} handleShowDemoRequestModal={handleShowDemoRequestModal} handleShowTryNowModal={handleShowTryNowModal} />} />
      <Route path="/shift-management-system" exact element={<ShiftManagement handleShowEarlyAccessModal={handleShowEarlyAccessModal} handleShowDemoRequestModal={handleShowDemoRequestModal} handleShowTryNowModal={handleShowTryNowModal} />} />
      <Route path="/hr-document-management-system" exact element={<DocumentManagement handleShowEarlyAccessModal={handleShowEarlyAccessModal} handleShowDemoRequestModal={handleShowDemoRequestModal} handleShowTryNowModal={handleShowTryNowModal} />} />
      <Route path="/employee-reports-trends" exact element={<ReportsTrends handleShowEarlyAccessModal={handleShowEarlyAccessModal} handleShowDemoRequestModal={handleShowDemoRequestModal} handleShowTryNowModal={handleShowTryNowModal} />} />
      <Route path="/hr-management-software" exact element={<CoreHr handleShowEarlyAccessModal={handleShowEarlyAccessModal} handleShowDemoRequestModal={handleShowDemoRequestModal} handleShowTryNowModal={handleShowTryNowModal} />} />
      <Route path="/blog" exact element={<Blog handleShowEarlyAccessModal={handleShowEarlyAccessModal} />} />
      <Route path="/blog/:id" exact element={<BlogDetail />} />
      <Route path="/coming-soon" exact element={<ComingSoon />} />
      <Route path="/attendance-workforce-management-software" exact element={<AttendanceWorkforceManagementSoftware />} />
      <Route path="/time-attendance-system-lp1" exact element={<LandingPage />} />
      <Route path="/time-attendance-software-ahmedabad-lp2" exact element={<LandingPageTwo />} />
      
      {/* page redirection */}
      <Route path="/attendance-management" element={<Navigate to="/attendance-management-system" />} />
      <Route path="/leave-management" element={<Navigate to="/leave-management-system" />} />
      <Route path="/shift-management" element={<Navigate to="/shift-management-system" />} />
      <Route path="/document-management" element={<Navigate to="/hr-document-management-system" />} />
      <Route path="/reports-trends" element={<Navigate to="/employee-reports-trends" />} />
      <Route path="/core-hr" element={<Navigate to="/hr-management-software" />} />
      <Route path="/touchless-attendance" element={<Navigate to="/biometric-touchless-attendance" />} />
      <Route path="/mobile-app" element={<Navigate to="/mobile-application" />} />
      <Route path="/our-story" element={<Navigate to="/mewurk-story" />} />
      <Route path="/partner" element={<Navigate to="/mewurk-partners" />} />
      
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </Suspense>
);


export default MainRoutes;
