export function injectMetaTagsGTM() {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const googleSiteVerification = process.env.REACT_APP_GOOGLE_SITE_VERIFICATION;
  const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
  const gaId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
  const zohoSalesIQWidgetUrl = process.env.REACT_APP_ZOHO_SALESIQ_WIDGET_URL;

  if (environment === 'production') {
    const head = document.head;
    const body = document.body;

    
    const insertBeforeStyle = (element) => {
      const styleElement = head.querySelector('style');
      if (styleElement) {
        head.insertBefore(element, styleElement);
      } else {
        head.appendChild(element);
      }
    };

    
    const insertBeforeBodyEnd = (element) => {
      body.insertBefore(element, body.childNodes[body.childNodes.length - 1]);
    };

    // Inject Google Site Verification meta tag
    if (googleSiteVerification) {
      const metaTag = document.createElement('meta');
      metaTag.name = 'google-site-verification';
      metaTag.content = googleSiteVerification;
      insertBeforeStyle(metaTag);
    }
    
    // Inject Google Tag Manager script
    if (gtmId) {
      const gtmScript = document.createElement('script');
      gtmScript.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');
      `;
      insertBeforeStyle(gtmScript);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      body.appendChild(noscript);
    }

    // Inject Google Analytics script
    if (gaId) {
      const gaScript = document.createElement('script');
      gaScript.async = true;
      gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
      insertBeforeStyle(gaScript);
      
      const inlineScript = document.createElement('script');
      inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${gaId}');
      `;
      insertBeforeStyle(inlineScript);
    }

    // Inject $zoho SalesIQ scripts
      const zohoScript1 = document.createElement('script');
      zohoScript1.innerHTML = `
        window.$zoho = window.$zoho || {};
        $zoho.salesiq = $zoho.salesiq || { ready: function() {} };
      `;
      insertBeforeBodyEnd(zohoScript1);
      // Load Zoho SalesIQ widget script
      const zohoScript2 = document.createElement('script');
      zohoScript2.id = 'zsiqscript';
      zohoScript2.src = zohoSalesIQWidgetUrl;
      zohoScript2.defer = true; 
      insertBeforeBodyEnd(zohoScript2);
  }
}
