import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import useFormValidation from '../../../hooks/useFormValidation';
import validate from '../../../hooks/validate';
import { Link } from 'react-router-dom';// Import the onSubmitTryNow function
import ThankYouModal from './ThankYouModal';
import { onSubmitTryNow } from './../constant/handleSubmit';

const TryNowModal = ({ show, handleClose }) => {
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const submitForm = async (values) => {
    await onSubmitTryNow(
      values,
      setShowThankYouModal,
      resetForm,
      setIsSubmitting,
      setSubmitError
    );
    handleClose();
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    resetForm
  } = useFormValidation(
    { firstName: '', email: '', subject: '', writeAMessage: '' },
    (values) => validate(values, 'tryNow'),
    submitForm
  );

  const handleModalClose = () => {
    handleClose();
    resetForm();
  };

  return (
    <>
      <Modal show={show} onHide={handleModalClose} centered>
        <div className="modal-content">
          <div className="modal-body">
            <Link className="close" onClick={handleModalClose} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </Link>
            <div className="form-div" id="quick-form">
              <Form id="tryNow" onSubmit={handleSubmit}>
                <h2 className="text-center font-25 pb-3 bluetxt">Book a Meeting with Mewurk Sales</h2>
                <Form.Group className="mb-4">
                  <div className="row">
                    <div className="col">
                      <Form.Control
                        type="text"
                        name="firstName"
                        id="name"
                        placeholder="Name*"
                        className={`form-control ${errors.firstName && 'is-invalid'}`}
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.firstName && <p className="error">{errors.firstName}</p>}
                    </div>
                    <div className="col">
                      <Form.Control
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email*"
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && <p className="error">{errors.email}</p>}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="mb-4">
                  <div className="row">
                    <div className="col">
                      <Form.Control
                        type="text"
                        name="subject"
                        id="subject"
                        placeholder="Subject*"
                        className={`form-control ${errors.subject && 'is-invalid'}`}
                        value={values.subject}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.subject && <p className="error">{errors.subject}</p>}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="mb-4">
                  <div className="row">
                    <div className="col">
                      <Form.Control
                        as="textarea"
                        rows={6}
                        name="writeAMessage"
                        type="text"
                        className={`form-control ${errors.writeAMessage && 'is-invalid'}`}
                        value={values.writeAMessage}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Write a message*"
                      />
                      {errors.writeAMessage && <p className="error">{errors.writeAMessage}</p>}
                    </div>
                  </div>
                </Form.Group>
                <div className="joinbtn-div">
                  <button className="btn-blue btn-join" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Sending...' : 'Send Message'}
                  </button>
                </div>
              </Form>
              {submitError && <p className="error mt-3">{submitError}</p>}
            </div>
          </div>
        </div>
      </Modal>
      <Modal show={showThankYouModal} onHide={() => setShowThankYouModal(false)} centered>
        <ThankYouModal onClose={() => setShowThankYouModal(false)} />
      </Modal>
    </>
  );
};

export default TryNowModal;
