import React from 'react';
import { Link } from 'react-router-dom';
import footerLogo from "../assets/images/footer-logo.svg"
import fb from "../assets/images/fb.svg"
import instagram from "../assets/images/instagram.svg"
import twitter from "../assets/images/twitter.svg"
import linkedin from "../assets/images/linkedin.png"
import {constant} from './common/constant/constant';

const Footer = () => {
  return (
    <>
    <section className="footer bg-darkblue pb-40 m-60">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3">
            <h4>Modules</h4>
            <ul className="footer-list">
              <li><Link to="/attendance-management-system">Attendance Management </Link></li>
              <li><Link to="/leave-management-system">Leave Management</Link></li>
              <li><Link to="/shift-management-system">Shift Management</Link></li>
              <li><Link to="/coming-soon">Task Management <span className="small-text">(Coming Soon)</span></Link></li>
              <li><Link to="/coming-soon">Onboarding and Offboarding <span className="small-text">(Coming Soon)</span></Link></li>
              <li><Link to="/coming-soon">Payroll Management <span className="small-text">(Coming Soon)</span></Link></li>
              <li><Link to="/hr-document-management-system">Document Management</Link></li>
              <li><Link to="/employee-reports-trends">Reports and Trends</Link></li>
              <li><Link to="/hr-management-software">Core HR</Link></li>
            </ul>
          </div>

          {/* About Us */}
          <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <h4>About us</h4>
            <ul className="footer-list">
              <li><Link to="/mewurk-story">Our Story</Link></li>
              <li><Link to="/our-team">Our Team </Link></li>
              <li><Link to="/contact-us">Contact us </Link></li>
              <li><Link to="/mewurk-partners">Partner with us </Link></li>
              <li><Link to="/careers">Careers </Link></li>
            </ul>
          </div>

          {/* Links */}
          <div className="col-xs-12 col-sm-12 col-md-2 col-lg-3">
            <h4>Links</h4>
            <ul className="footer-list">
              <li><Link to="/privacy-policy">Privacy Policy </Link></li>
              <li><Link to="/terms-conditions">Terms Of Use </Link></li>
              <li><Link to="/refund-policy">Cancellation & Refund Policy </Link></li>
            </ul>
          </div>

          {/* Address and Contact */}
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <Link to="index"><img src={footerLogo} className="img-fluid" alt="logo" /></Link>
            <p className="text-white mt-3 ftxt">Empowering SMEs with modern-age, cloud-based Attendance and Workforce Management systems that help save time and boost productivity.</p>
            <div className="address-f">
              <h5>Address :</h5>
              <p>B 203, Shapath Hexa, SG Hwy, Sola, Ahmedabad, Gujarat 380060</p>
            </div>
            <div className="sale-f">
              <div className="for-heading">For Sales <span className="hidet">&nbsp;&nbsp;&nbsp;&nbsp;</span>:</div>
              <div className="for-content">
                <p>
                  <Link to={`mailto:${constant.emailId}`}>{constant.emailId}</Link> <br />
                  <Link to={`tel:${constant.phoneNumber}`}>{constant.phoneNumber}
                  </Link>
                </p>
              </div>
            </div>
            <div className="sale-f">
              <div className="for-heading">
                For Support :
              </div>
              <div className="for-content">
                <p>
                  <Link to={`mailto:${constant.emailIdSupport}`}>{constant.emailIdSupport}</Link> <br />
                  <Link to={`tel:${constant.phoneNumberSupport}`}>{constant.phoneNumberSupport}</Link>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Social Media Icons and Copyright */}
        <div className="row footer-bottom">
          <div className="col-md-12 text-center text-white">
            <p>
              <Link to={constant.mewurkfb} target="_blank">
                <img src={fb} className="img-fluid m-1" alt="fb" />
              </Link>
              <Link to={constant.mewurkinsta} target="_blank">
                <img src={instagram} className="img-fluid m-1" alt="instagram" />
              </Link>
              <Link to={constant.mewurktwitter} target="_blank">
                <img src={twitter} className="img-fluid m-1" alt="twitter" />
              </Link>
              <Link to={constant.mewurklinkedin} target="_blank">
                <img src={linkedin} className="img-fluid m-1" alt="linkdin" />
              </Link>
            </p>
            <p className="m-0">Copyright 2024 - All rights reserved</p>
          </div>
        </div>
      </div>
    </section>
    {/* <ModalsContainer /> */}
    </>
  );
};

export default Footer;

