import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppContent from './components/AppContent';
import { JobProvider } from './components/common/Careers/JobContext';

const App = () => (
  <JobProvider>
    <Router>
      <AppContent />
    </Router>
  </JobProvider>
);

export default App;
