import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import useFormValidation from '../../../hooks/useFormValidation';
import validate from '../../../hooks/validate';
import { submitEarlyAccessRequest } from '../../../services/apiService'; 
import ThankYouModal from './ThankYouModal';
import handleFormSubmit from '../constant/handleSubmit';

const EarlyAccessModal = ({ show, handleClose }) => {
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const submitForm = async (values, resetForm) => {
    await handleFormSubmit(
      submitEarlyAccessRequest,
      values,
      setShowThankYouModal,
      resetForm,
      () => {}, 
      setIsSubmitting,
      setSubmitError
    );
    handleClose();
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    resetForm,
  } = useFormValidation(
    { email: '', phone: '', numberOfEmployeess: '' },
    (values) => validate(values, 'earlyAccess'), 
    (values) => submitForm(values, resetForm) 
  );

  // Reset form when modal is closed
  const handleCloseModal = () => {
    handleClose();
    resetForm();
  };

  return (
    <>
      <Modal show={show} onHide={handleCloseModal} centered>
        <Modal.Body>
          <button className="close" onClick={handleCloseModal} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="form-div" id="quick-form">
            <form id="startFreeTrial" onSubmit={handleSubmit}>
              <h2 className="text-center banner-form-heading bluetxt">Join Early Access Program Today</h2>
              <p className="text-center formh-txt bluetxt">(1 year FREE subscription + Biometric device)</p>
              <div className="form-group">
                <label>Email*</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
              <div className="form-group">
                <label>Phone Number*</label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  className={`form-control ${errors.phone && 'is-invalid'}`}
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.phone && <p className="error">{errors.phone}</p>}
              </div>
              <div className="form-group">
                <label>Numbers of Employee*</label>
                <select
                  className={`form-control ${errors.numberOfEmployeess && 'is-invalid'}`}
                  name="numberOfEmployeess"
                  value={values.numberOfEmployeess}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Please Select</option>
                  <option>1-50</option>
                  <option>51-100</option>
                  <option>101-200</option>
                  <option>201-500</option>
                </select>
                {errors.numberOfEmployeess && <p className="error">{errors.numberOfEmployeess}</p>}
              </div>
              <div className="joinbtn-div">
                <button className="btn-blue btn-join" type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Join Today'}
                </button>
                {submitError && <p className="error">{submitError}</p>}
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showThankYouModal} onHide={() => setShowThankYouModal(false)} centered>
        <ThankYouModal onClose={() => setShowThankYouModal(false)} />
      </Modal>
    </>
  );
};

export default EarlyAccessModal;
