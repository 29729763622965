import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './assets/styles/Global.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import $ from 'jquery';
import 'jquery-validation';
import { injectMetaTagsGTM } from './utils/injectScripts';

// Ensure jQuery is available globally
window.jQuery = $;
window.$ = $;

injectMetaTagsGTM();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);
