import React from 'react';
import blogTime from '../../../assets/images/blog-time.svg'
import calender from '../../../assets/images/blog-calender.svg'

const BlogPost = ({ title, imageSrc, description, date, readingTime, link }) => {
  return (    
    <div className="card card-box-blog h-100">
        <div className="d-flex flex-column">
        <div className="card-body">
            <img src={imageSrc} className="card-img img-fluid blog-img" alt="blog" />
            <div className="desc-div">
            <h2 className="font-20 font-weight-bold bluetxt ptb-10">{title}</h2>
            <p className="font-15 black-text">{description}</p>
            </div>
        </div>
        <div className="row card-footer">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <p>
                <img src={calender} className="img-fluid mr-1" alt="icon" />
                {date}
            </p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <p>
                <img src={blogTime} className="img-fluid mr-1" alt="icon" />
                {readingTime}
            </p>
            </div>
        </div>
        </div>
    </div>
  );
};

export default BlogPost;

