import React from 'react'
import logo from '../../assets/images/logo.svg'
import landingWhy from '../../assets/images/landing-why.svg';
import landingSmile from '../../assets/images/landing-smile.svg';
import landingUser1 from '../../assets/images/landing-user1.svg';
import landingUser2 from '../../assets/images/landing-user2.svg';
import landingQuote from '../../assets/images/landing-quote.svg';
import landingFiveStar from '../../assets/images/landing-five-star.svg';
import landing1 from '../../assets/images/landing1.svg';
import landing2 from '../../assets/images/landing2.svg';
import landing3 from '../../assets/images/landing3.svg';
import landing4 from '../../assets/images/landing4.svg';
import landing5 from '../../assets/images/landing5.svg';
import downarrow from '../../assets/images/downarrow.png';
import fbIcon from '../../assets/images/fb.svg';
import instagramIcon from '../../assets/images/instagram.svg';
import twitterIcon from '../../assets/images/twitter.svg';
import linkedinIcon from '../../assets/images/linkedin.png';
import attendanceImage from '../../assets/images/landing-attendance.png'; 
import icon1 from "../../assets/images/landingatt1.svg"
import kioskImage from '../../assets/images/landing-kiosk.png';  
import kioskIcon from '../../assets/images/landing-kioskicon.svg'; 
import leaveIcon from '../../assets/images/landing-leave-icon.svg';  
import leaveImage from '../../assets/images/landing-leave.png'; 
import shiftImage from '../../assets/images/landing-shift.png';  
import shiftIcon from '../../assets/images/landing-shifticon.svg';  
import coreHRIcon from '../../assets/images/landing-corehricon.svg';  
import coreHRImage from '../../assets/images/landing-corehr.png';  
import payrollIcon from '../../assets/images/landing-payroll-icon.svg'; 
import payrollImage from '../../assets/images/landing-payroll-finalisation.png';  
import BannerSection from '../../components/common/LandingPage/Banner'
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import {constant} from '../../components/common/constant/constant';


const AttendanceWorkforceManagementSoftware = () => {
  return (
    <div>
      <section className="header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 text-left mobile-left">
              <Link to="/">
                <img src={logo} alt="logo" className="img-fluid" />
              </Link>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 text-right mobile-right">
              <ScrollLink to="quick-form" smooth={true} duration={500} className="text-white btn-join btn-orange book-button">
                Book a FREE Demo
              </ScrollLink>
            </div>
          </div>
        </div>
      </section>
      <BannerSection />
      <section className="why-mewurk p-60 pb-60">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12">
              <h4 className="bluetxt font-35">Why Mewurk ?</h4>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <img src={landingWhy} className="img-fluid" alt="why" />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <ul className="timeline">
                <li>
                  <p>Mewurk's streamlined solutions are tailored for SMEs to optimize their workforce management processes.</p>
                </li>
                <li>
                  <p>Hosted in the cloud with just a click. Highly secure geographically distributed servers. </p>
                </li>
                <li>
                  <p>Best and simplified UI/UX in the industry, complete UI is crafted after researching more than 12 players</p>
                </li>
                <li>
                  <p>Single workforce management software and supports employee self service to empower them </p>
                </li>
                <li>
                  <p>Assists Admin and HR to set workflows and manage multiple tasks from backend</p>
                </li>
                <li>
                  <p>Helps saving more than 50% of HR and owners time doing HR tasks and automate them in minutes</p>
                </li>
              </ul>
              <div className="pt-3"><ScrollLink to="quick-form" smooth={true} duration={500} className="margin-l5 text-white btn-join btn btn-orange">Register Now</ScrollLink></div>
            </div>
          </div>
        </div>
      </section>
      <section className="attendence pb-60 p-60 bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="module-img">
                <img src={icon1} className="img-fluid" alt="b1" />
              </div>
              <h2 className="bluetxt font-35 ptb-20">Time and Attendance</h2>
              <ul className="blue-list font-20">
                <li>Streamlined Check-in/out</li>
                <li>Avoid Buddy punching</li>
                <li>Face recognition based attendance system</li>
                <li>Add multiple policies and workflows</li>
                <li>Both mobile app and desktop along with geotagging</li>
              </ul>
              <ScrollLink to="quick-form" smooth={true} duration={500} className="text-white btn-join btn-blue">Try Now For FREE</ScrollLink>
            </div>
            <div className="col-md-7">
              <img src={attendanceImage} className="img-fluid image-shadow" alt="attendance" />
            </div>
          </div>
        </div>
      </section>
      <section className="kiosk p-60 pb-60">
        <div className="container">
          <div className="row col-reverse">
            <div className="col-md-7">
              <img src={kioskImage} className="img-fluid image-shadow" alt="kiosk" />
            </div>
            <div className="col-md-5">
              <div className="ml-md-4">
                <div className="module-img">
                  <img src={kioskIcon} className="img-fluid" alt="kioskicon" />
                </div>
                <h2 className="orangetxt font-35 ptb-20">Kiosk Management</h2>
                <ul className="yellow-list font-20">
                  <li>Leaders in attendance kiosk manufacturing</li>
                  <li>Face recognition-based attendance system</li>
                  <li>Zero data loss</li>
                  <li>AI-enabled device</li>
                </ul>
                <ScrollLink to="quick-form" smooth={true} duration={500} className="text-white btn-join btn-orange">Get Early Access</ScrollLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="leave p-60 bg-gray pb-60">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="module-img">
                <img src={leaveIcon} className="img-fluid" alt="leave-icon" />
              </div>
              <h2 className="bluetxt font-35 ptb-20">Leave management</h2>
              <ul className="blue-list font-20">
                <li>Flexible leave policies</li>
                <li>Automate leave approvals workflows</li>
                <li>Bulk leave approvals</li>
                <li>Manage comp-offs and holiday calendar</li>
              </ul>
              <ScrollLink to="quick-form" smooth={true} duration={500} className="text-white btn-join btn-blue">Try Now For FREE</ScrollLink>
          </div>
          <div className="col-md-7">
            <img src={leaveImage} className="img-fluid image-shadow" alt="leave" />
          </div>
        </div>
      </div>
      </section>
      <section className="shift-management p-60 pb-60">
        <div className="container">
          <div className="row col-reverse">
            <div className="col-md-7">
              <img src={shiftImage} className="img-fluid image-shadow" alt="shift" />
            </div>
            <div className="col-md-5">
              <div className="ml-md-4">
                <div className="module-img">
                  <img src={shiftIcon} className="img-fluid" alt="shifticon" />
                </div>
                <h2 className="orangetxt font-35 ptb-20">Shift Management</h2>
                <ul className="yellow-list font-20">
                  <li>Flexible Shift policies</li>
                  <li>Auto shift scheduler for easy assignment</li>
                  <li>Manage shift roster</li>
                  <li>Assignment with Unique shift patterns</li>
                </ul>
                <ScrollLink to="quick-form" smooth={true} duration={500} className="text-white btn-join btn-orange">Get Early Access</ScrollLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="core-hr p-60 pb-60 bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="module-img">
                <img src={coreHRIcon} className="img-fluid" alt="corehricon" />
              </div>
              <h2 className="bluetxt font-35 ptb-20">Core HR</h2>
              <ul className="blue-list font-20">
                <li>Tasks and workflows accessed from one place</li>
                <li>Bulk upload of documents and digitization of documents</li>
                <li>Quick company and function setup</li>
                <li>HR analytics and people insights</li>
                <li>Complete trends module wise to make better business decisions</li>
              </ul>
              <ScrollLink to="quick-form" smooth={true} duration={500} className="text-white btn-join btn-blue">Try Now For FREE</ScrollLink>
            </div>
            <div className="col-md-7">
              <img src={coreHRImage} className="img-fluid image-shadow" alt="corehr" />
            </div>
          </div>
        </div>
      </section>
      <section className="payroll-finalisation p-60 pb-60">
        <div className="container">
          <div className="row col-reverse">
            <div className="col-md-7">
              <img src={payrollImage} className="img-fluid image-shadow" alt="payroll-finalisation" />
            </div>
            <div className="col-md-5">
              <div className="ml-md-4">
                <div className="module-img">
                  <img src={payrollIcon} className="img-fluid" alt="payroll-icon" />
                </div>
                <h2 className="orangetxt font-35 ptb-20">Payroll Finalisation</h2>
                <ul className="yellow-list font-20">
                  <li>Add multiple groups/templates as per your need</li>
                  <li>Integrated attendance-payroll system</li>
                  <li>Calculate payroll with 100% accuracy</li>
                  <li>Multiple Field customization and Mapping</li>
                  <li>Process Payroll with multiple partners</li>
                </ul>
                <ScrollLink to="quick-form" smooth={true} duration={500} className="text-white btn-join btn-orange">Get Early Access</ScrollLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonial pb-60">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h2 className="bluetxt font-35 ptb-20">What Our Current Beta Users Say About Us</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonial-sec p-60 pb-60">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <img src={landingSmile} alt="smile" className="img-fluid" />
              <h2 className="mt-4 text-white font-n38">
                Customer <br />
                <span className="font-44">Happiness</span> <br />
                <span className="font-n50">is our</span> <span className="font-50">Priority</span>
              </h2>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-6 mobile-margin-20">
                  <div className="user-testi">
                    <p className="font-n25">We saw 83% reduction in manual efforts for attendance and leave management</p>
                    <div className="user-div">
                      <div className="image">
                        <img src={landingUser1} className="img-fluid" alt="user" />
                      </div>
                      <div className="text">
                        <p className="orangetxt font-n20 m-0">Bipin Kumar</p>
                        <p className="m-b12 font-12">HR and Operations</p>
                        <img src={landingFiveStar} alt="star" className="img-fluid" />
                      </div>
                      <div className="quote">
                        <img src={landingQuote} alt="quote" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mobile-margin-20">
                  <div className="user-testi">
                    <p className="font-n25">We saw 83% reduction in manual efforts for attendance and leave management</p>
                    <div className="user-div">
                      <div className="image">
                        <img src={landingUser2} className="img-fluid" alt="user" />
                      </div>
                      <div className="text">
                        <p className="orangetxt font-n20 m-0">Rajesh Prasad</p>
                        <p className="m-b12 font-12">Head HR</p>
                        <img src={landingFiveStar} alt="star" className="img-fluid" />
                      </div>
                      <div className="quote">
                        <img src={landingQuote} alt="quote" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-left margin-x2-y6">
                  <ScrollLink to="quick-form" smooth={true} duration={500} className="text-white btn-join btn-blue white-bg-orangetext">Book a Demo</ScrollLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faq pt-60">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h2 className="bluetxt font-35 ptb-20">Frequently Asked Questions</h2>
            </div>
            <div className="col-md-12 align-items-center">
              <div className="accordion" id="faq">
                <div className="card">
                  <div className="card-header" data-toggle="collapse" data-target="#faq1" aria-expanded="true" role="navigation">
                    <span className="bluetxt font-30"><img src={landing1} className="img-fluid" alt="1" /> How can we take part in beta Program </span>
                    <span className="accicon"><img src={downarrow} className="img-fluid rotate-icon" alt="right-arrow" /></span>
                  </div>
                  <div id="faq1" className="collapse show" data-parent="#faq">
                    <div className="card-body font-20"> Just fill the form for early access waitlist and our executives will get back to you. </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header collapsed" data-toggle="collapse" data-target="#faq2" aria-expanded="false" role="navigation" aria-controls="faq2">
                    <span className="bluetxt font-30"><img src={landing2} className="img-fluid" alt="2" /> How can I get started ?</span>
                    <span className="accicon"><img src={downarrow} className="img-fluid rotate-icon" alt="right-arrow" /></span>
                  </div>
                  <div id="faq2" className="collapse" data-parent="#faq">
                    <div className="card-body font-20"> Just fill out the form for early access and our executive will get back to you with all the details. </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header collapsed" data-toggle="collapse" data-target="#faq3" aria-expanded="false" role="navigation">
                    <span className="bluetxt font-30"><img src={landing3} className="img-fluid" alt="3" /> What Features are available with Beta Early Access Version?</span>
                    <span className="accicon"><img src={downarrow} className="img-fluid rotate-icon" alt="right-arrow" /></span>
                  </div>
                  <div id="faq3" className="collapse" data-parent="#faq">
                    <div className="card-body font-20"> Beta Version includes - Complete attendance management, Leave and shift management, Onboarding and offboarding, Employee profile management, team and org structure, Admin roles to manage workflow and policies, file management, Reports and trends, beautiful actionable dashboard. </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header collapsed" data-toggle="collapse" data-target="#faq4" aria-expanded="false" role="navigation">
                    <span className="bluetxt font-30"><img src={landing4} className="img-fluid" alt="4" /> What is included as a part of Early access program</span>
                    <span className="accicon"><img src={downarrow} className="img-fluid rotate-icon" alt="right-arrow" /></span>
                  </div>
                  <div id="faq4" className="collapse" data-parent="#faq">
                    <div className="card-body font-20"> Top Early access participant will able to access our HRMS with Hardware kiosk free of cost for a limited time period, with complete technical support. </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header collapsed" data-toggle="collapse" data-target="#faq5" aria-expanded="false" role="navigation">
                    <span className="bluetxt font-30"><img src={landing5} className="img-fluid" alt="5" /> What Features are available with Beta Early Access Version?</span>
                    <span className="accicon"><img src={downarrow} className="img-fluid rotate-icon" alt="right-arrow" /></span>
                  </div>
                  <div id="faq5" className="collapse" data-parent="#faq">
                    <div className="card-body font-20"> Beta Version includes - Complete attendance management, Leave and shift management, Onboarding and offboarding, Employee profile management, team and org structure, Admin roles to manage workflow and policies, file management, Reports and trends, beautiful actionable dashboard. </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="try-sec pb-60">
        <div className="container-fluid try-sec-bg">
        <div className="row">
          <div className="col-md-12">
            <div className="content-try">
              <h2 className="text-white font-35">Ready to give it a try?</h2>
              <p className="text-white font-20 m-0">The World’s Simplest productivity solution, that</p>
              <p className="text-white font-20 m-0">saves a lot of time and cost. </p>
              <div className="my-3"><ScrollLink to="quick-form" smooth={true} duration={500} className="margin-16 text-white btn-join btn-orange white-bg-bluetext">Register for Early Access Program</ScrollLink></div>
              <ul className="try-list font-20 text-white">
                <li>Get yourself on waitlist for FREE access</li>
                <li>Onboard your company in an Hour </li>
              </ul>
            </div>
          </div>
        </div>
        </div>
      </section>
      <section className="footer bg-dark ptb-20">
        <div className="container-fluid">
          <div className="row footer-bottom">
            <div className="col-md-12 text-center text-white">
              <p>
              <Link to={constant.mewurkfb} target="_blank" rel="noopener noreferrer">
                  <img src={fbIcon} className="img-fluid m-1" alt="fb" />
              </Link>
                <Link to={constant.mewurkinsta} target="_blank" rel="noopener noreferrer">
                  <img src={instagramIcon} className="img-fluid m-1" alt="instagram" />
                </Link>
              <Link to={constant.mewurktwitter} target="_blank" rel="noopener noreferrer">
                  <img src={twitterIcon} className="img-fluid m-1" alt="twitter" />
              </Link>
              <Link to={constant.mewurklinkedin} target="_blank" rel="noopener noreferrer">
                  <img src={linkedinIcon} className="img-fluid m-1" alt="linkedin" />
              </Link>
              </p>
              <p className="m-0">Copyright 2024 - All rights reserved</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AttendanceWorkforceManagementSoftware
