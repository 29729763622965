import React, { useState } from 'react';
import useFormValidation from '../../../hooks/useFormValidation';
import validate from '../../../hooks/validate';
import { submitDemoRequest } from '../../../services/apiService'; 
import { Modal } from 'react-bootstrap';
import ThankYouModal from '../Modal/ThankYouModal';
import Subscribe from './Subscribe';
import handleFormSubmit from '../constant/handleSubmit';

const BlogSidebar = () => {
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const submitForm = async (values) => {
    await handleFormSubmit(
      submitDemoRequest, 
      values,
      setShowThankYouModal,
      resetForm,
      () => {}, 
      setIsSubmitting,
      setSubmitError
    );
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    resetForm,
  } = useFormValidation(
    { email: '', phone: '' },
    (values) => validate(values, 'demoBlog'),
    submitForm
  );

  return (
    <div className="col-lg-4">
      <div className="blog-sidebar mt-3">
        <div className="sidebar-form form-blog">
          <form onSubmit={handleSubmit}>
            <h2 className="bluetxt font-25">Feel the Ease of Mewurk</h2>
            <div className="form-group">
              <input
                type="email"
                name="email"
                className={`form-control ${errors.email && 'is-invalid'}`}
                placeholder="Email Id"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="phone"
                className={`form-control ${errors.phone && 'is-invalid'}`}
                placeholder="Phone Number"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.phone && <p className="error">{errors.phone}</p>}
            </div>
            <button
              type="submit"
              className="btn-join btn-orange text-white"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Schedule a Demo'}
            </button>
            {submitError && <p className="error">{submitError}</p>}
          </form>
          <Modal
            show={showThankYouModal}
            onHide={() => setShowThankYouModal(false)}
            centered
          >
            <ThankYouModal onClose={() => setShowThankYouModal(false)} />
          </Modal>
        </div>
        <Subscribe />
      </div>
    </div>
  );
};

export default BlogSidebar;
