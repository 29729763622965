import React, { useState, useEffect } from 'react';
import axios from 'axios';

import userAvtar from '../../../assets/images/blog-user1.png'

const CommentSection = () => {
  return (
    <section className="comment p-25">
      <div className="container-fluid">
        <div className="row text-white">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
            <div className="comment-form form-blog pt-20">
              <form>
                <h1 className="black-text font-30">Leave Comment</h1>
                <div className="form-group">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name*"
                        value=""
                        required
                      />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email*"
                        value=""
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col">
                      <textarea
                        className="form-control"
                        name="message"
                        rows="8"
                        placeholder="Write A Message*"
                        value=""
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn-join btn-orange text-white">Send Message</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommentSection;
