import React, { useState } from 'react';
import useFormValidation from '../../../hooks/useFormValidation';
import validate from '../../../hooks/validate';
import { Modal } from 'react-bootstrap';
import ThankYouModal from '../Modal/ThankYouModal';
import { handleBlogSubmit } from '../constant/handleSubmit';

const Subscribe = () => {
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const handleSubmitWithConstants = async (values) => {
    setSubmitError('');
    await handleBlogSubmit(
      values,
      setIsSubmitting,
      setShowThankYouModal,
      resetForm,
      setSubmitError
    );
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    resetForm,
  } = useFormValidation(
    { email: '' },
    (values) => validate(values, 'blog'),
    handleSubmitWithConstants
  );

  const handleCloseModal = () => {
    setShowThankYouModal(false);
    setSubmitError('');
  };

  return (
    <div className="sidebar-form form-blog mt-5">
      <form onSubmit={handleSubmit}>
        <h2 className="bluetxt font-25">Get Fresh Blog alerts</h2>
        <div className="form-group">
          <input
            type="email"
            name="email"
            className={`form-control ${errors.email && 'is-invalid'}`}
            placeholder="Business Email id"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSubmitting}
          />
          {errors.email && <p className="error">{errors.email}</p>}
          {submitError && <p className="error mt-2">{submitError}</p>}
        </div>
        <button
          type="submit"
          className="btn-join btn-orange text-white"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Sending...' : 'Submit Now'}
        </button>
      </form>
      <Modal
        show={showThankYouModal}
        onHide={handleCloseModal}
        centered
      >
        <ThankYouModal onClose={handleCloseModal} />
      </Modal>
    </div>
  );
};

export default Subscribe;
