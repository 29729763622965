import { errors } from "../components/common/constant/constant";

const validate = (values, formType) => {
  let validationErrors = {};

  // Validate common fields
  if (!values.email) {
    validationErrors.email = errors.enterEmail;
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    validationErrors.email = errors.validEmail;
  }

  const phoneValidation = () => {
    if (!values.phone) {
      validationErrors.phone = errors.enterPhone;
    } else if (!/^\d{10}$/.test(values.phone)) {
      validationErrors.phone = errors.validPhone;
    }
  };

  if (formType === 'demoRequest' || formType === 'demoBlog' || formType === 'earlyAccess' || formType === 'partnerForm' || formType === 'landingPageForm') {
    phoneValidation();
  }

  if (formType === 'careerForm' || formType === 'partnerForm') {
    phoneValidation();
    if (!values.firstName || !values.firstName.trim()) {
      validationErrors.firstName = errors.enterFirstName;
    }
    if (!values.lastName || !values.lastName.trim()) {
      validationErrors.lastName = errors.enterLastName;
    }
  }

  if (formType === 'careerForm') {
    if (!values.attachmentFile) {
      validationErrors.attachmentFile = errors.uploadResume;
    }
  }

  if (formType === 'partnerForm') {
    if (!values.companyName) {
      validationErrors.companyName = errors.enterCompanyName;
    }
    if (!values.location) {
      validationErrors.location = errors.enterLocation;
    }
  }

  if (formType === 'demoRequest' || formType === 'tryNow') {
    if (!values.firstName || !values.firstName.trim()) {
      validationErrors.firstName = errors.enterName;
    }
  }

  if (formType === 'tryNow') {
    if (!values.subject || !values.subject.trim()) {
      validationErrors.subject = errors.enterSubject;
    }
    if (!values.writeAMessage || !values.writeAMessage.trim()) {
      validationErrors.writeAMessage = errors.writeMessage;
    }
  }

  if (formType === 'earlyAccess') {
    if (!values.numberOfEmployeess || !values.numberOfEmployeess.trim()) {
      validationErrors.numberOfEmployeess = errors.selectEmployees;
    }
  }

  if (formType === 'landingPageForm') {
    if (!values.firstName) {
      validationErrors.firstName = errors.enterFullName;
    }
    if (!values.email) {
      validationErrors.email = errors.enterBusinessEmail;
    }
    if (!values.companyName) {
      validationErrors.companyName = errors.enterCompanyName;
    }
    if (!values.numberOfEmployeess) {
      validationErrors.numberOfEmployeess = errors.selectEmployees;
    }
  }

  return validationErrors;
};

export default validate;
