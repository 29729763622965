import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EmailSubscribeModal from '../common/Modal/EmailSubscribeModal';
import useFormValidation from '../../hooks/useFormValidation';
import validate from '../../hooks/validate';
import { submitDemoRequest } from '../../services/apiService';
import handleFormSubmit from './constant/handleSubmit';


const Banner = ({
  title,
  subtitle,
  imageSrc,
  bgColor,
  paddTopBott,
  showForm,
  btnaction,
  btntextcolor,
  bannerbtntxt,
  btnactionPartner,
  handleShowEarlyAccessModal,
  formButtonText = "Book Free Demo"
}) => {
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const submitForm = async (values) => {
    await handleFormSubmit(
      submitDemoRequest, 
      values,
      setShowThankYouModal,
      resetForm,
      () => {}, 
      setIsSubmitting,
      setSubmitError
    );
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    resetForm,
  } = useFormValidation(
    { email: '' },
    (values) => validate(values, ''),
    submitForm
  );

  const handleScrollToPartnerForm = () => {
    const formSection = document.getElementById('partnerForm');
    if (formSection) {
      formSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <section className={`${bgColor} ${paddTopBott}`}>
        <div className="container-fluid">
          <div className="row align-items-center text-white">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <div className="inner-banner">
                <h1>{title}</h1>
                <p>{subtitle}</p>
                {showForm && (
                  <div className="form-banner mt-30">
                    <form id="e-demo" onSubmit={handleSubmit}>
                      <div className="input-group">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Enter Your Email Address"
                          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ color: 'white' }}
                        />
                        <button
                          className={`btnbanner ${btntextcolor}`}
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? 'Sending...' : formButtonText}
                        </button>
                      </div>
                    </form>
                    <div>
                      {errors.email && (
                        <p className="error">{errors.email}</p>
                      )}
                      {submitError && <p className="error">{submitError}</p>}
                    </div>
                  </div>
                )}
                {btnaction && (
                  <div className="mt-30 bannermobilebootom">
                    <Link
                      onClick={handleShowEarlyAccessModal}
                      className={`btn-join btn-white ${btntextcolor}`}
                    >
                      {bannerbtntxt}
                    </Link>
                  </div>
                )}
                {btnactionPartner && (
                  <div className="mt-30">
                    <Link
                      to="#"
                      onClick={handleScrollToPartnerForm}
                      className={`btn-join btn-white ${btntextcolor}`}
                    >
                      {bannerbtntxt}
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <img
                src={imageSrc}
                alt="banner"
                className="img-fluid float-lg-right"
              />
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={showThankYouModal}
        onHide={() => setShowThankYouModal(false)}
        centered
      >
        <EmailSubscribeModal onClose={() => setShowThankYouModal(false)} />
      </Modal>
    </>
  );
};

export default Banner;
